// import React from 'react'
import axios from 'axios';
import { useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster } from '../functions/O3developer';
import Header from '../components/Header';
import Nav from '../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import { PulseLoader } from 'react-spinners';

function ChangePassword() {

    const navigate = useNavigate();
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [state, setState] = useState({
        old_password: '',
        password: '',
        confirm_password: '',
    });  
     
    async function proceed(e) {
        e.preventDefault();
        if (state.old_password == "" || state.password == "" || state.confirm_password == "") return toaster("error", error.validation.requiredInput);
        if(!loading) {
            dispatch({type: 'start'});
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/user/change-password",  {
                old_password: state.old_password,
                password: state.password,
                confirm_password: state.confirm_password,
            }).then((response) => {
                // console.log(response.data);
            if (response.data.status == "failed" && response.data.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    dispatch({type: 'stop'});
                    return toaster("success", response.data.message);
                } else {
                    return toaster("error", error.loading.excemption);    
                }
            }).catch((e) => {   
            if (e.response !== undefined) {
                if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                    storage("remove", "data", null, "localStorage");
                    return navigate("/login");
                }
            }
                return toaster("error", error.whoops);
                // return e;
            })
        }

    }
   

  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-2 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '17px' }}> Change Password </span> 
                        <span className='' style={{ marginLeft: '5px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            To reduce the possibility that your password will be guessed, enter a strong password.
                        </span>                      
                        </div> 
                    </div>
                    
                  <img src={"/images/password-icon.png"} width={55} height={55} style={{ borderRadius: '50%' }} className='fw_img_shadow'/>
                
                </div> 


                    
          <div className='mt-2 mb-0'>
            <div className="form-group has-feedback mb-2">            
              <input type="password" autoCorrect='off' autoComplete='off' placeholder='Enter your old password'
              onChange={(e) => setState({...state, old_password: e.target.value})} 
              className="form-control input-area" id="old_password" /> 
            <span className="fas fa-arrow-left form-control-feedback text-dark mt-3 text-danger"></span>
            </div>
        </div>


        <div className='mt-0 mb-0'>
            <div className="form-group has-feedback mb-2">            
              <input type="password" autoCorrect='off' autoComplete='off'
                onChange={(e) => setState({...state, password: e.target.value})}               
                placeholder='Enter your new password' className="form-control input-area" id="password" /> 
            <span className="fas fa-key form-control-feedback text-dark mt-3 text-success"></span>
            </div>
        </div>

        <div className='mt-0 mb-1'>
            <div className="form-group has-feedback mb-2">            
              <input type="password" autoCorrect='off' autoComplete='off'
                onChange={(e) => setState({ ...state, confirm_password: e.target.value })}              
                placeholder='Retype your new password' className="form-control input-area" id="confirm_password" /> 
            <span className="fas fa-key form-control-feedback text-dark mt-3 text-success"></span>
            </div>
        </div>


        <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
        { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loading ? '' : 'Proceed ' }
          { !loading && <i className='fas fa-circle-arrow-right'></i> }
      </button>


              
            </div>
        </div>
        



       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default ChangePassword