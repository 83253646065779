import React from 'react'
import { useNavigate } from 'react-router';
import { storage } from '../functions/O3developer';

function Header() {
    const navigate = useNavigate();
  const logOff = () => {
    if(storage("remove", "data", null, "localStorage")) {
        return navigate("/login");
    }
  }

  return (
    <div>
        
        <header className="main-header shadow header_">
    <div className="inside-header">
        <div className="d-flex align-items-center logo-box justify-content-between">	
          <a href="#" className="logo">			  
            <div className="logo-mini">
                {/* <span className="light-logo"><img src="../images/logo-dark.png" alt="logo"/></span> */}
                {/* <!-- <span className="dark-logo"><img src="../images/logo-dark.png" alt="logo"/></span> --> */}
            </div>
            <div className="logo-lg">
                {/* <!-- <span className="light-logo"><img src="../images/logo-dark-text.png" alt="logo"></span> --> */}
                {/* <!-- <span className="dark-logo"><img src="../images/logo-light-text.png" alt="logo"></span> --> */}
                <span className="text-white text-bold text-uppercase font-size-14"> TELECOMPLUG </span>
            </div>
          </a>	
        </div>
        
        
      {/* Header Navbar  */}
      <nav className="navbar navbar-static-top" style={{ background: "white" }}>
        {/* Sidebar toggle button */}
        <div>		  
            <a href="#" data-provide="fullscreen" className="sidebar-toggle d-lg-inline-flex d-none" title="Full Screen">
              <i className="mdi mdi-crop-free"></i>
            </a> 

        </div>
        
        <div></div><img src='/images/Telecomplug-logo-transaparent.png' width={35}/>


        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            {/* <!-- full Screen --> */}
            {/* <li className="search-bar">		  
                <div className="lookup lookup-circle lookup-right">
                   <input type="text" name="s"/>
                </div>
            </li>			 */}
            {/* <!-- Messages --> */}
            <li className="dropdown messages-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" title="Messages">
                <i className="mdi mdi-email"></i>
              </a>
              <ul className="dropdown-menu animated bounceIn">

                <li className="header">
                  <div className="p-20">
                      <div className="flexbox">
                          <div>
                              <h4 className="mb-0 mt-0">Messages</h4>
                          </div>
                          <div>
                              <a href="#" className="text-danger">Clear All</a>
                          </div>
                      </div>
                  </div>
                </li>
                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    
                    {/* <!-- end message --> */}
                    
                    
                   
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img src="/images/Telecomplug-logo-transaparent.png" className="rounded-circle" alt="User Image"/>
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Hello
                            <small><i className="fa fa-clock-o"></i> now </small>
                           </h4>
                           <span>Welcome to Telecomplug</span>
                        </div>

                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">				  
                    <a href="#">See all</a>
                </li>
              </ul>
            </li>
            {/* <!-- Notifications --> */}
            <li>
              <a href="#" onClick={logOff} className="dropdown-toggle">
                <i className="fas fa-power-off"></i>
              </a>
             
            </li>	
 
          </ul>
        </div>
      </nav>
    </div>
</header>

    </div>
  )
}

export default Header