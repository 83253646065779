import React from 'react'

function Welcome_Social(props) {

const iconColor = "#475f7b";
const refLink = window.location.origin +'/register?ref='+ props.user.username;
const shareDialogue = () => {
    if (typeof navigator.share === 'function') {
    navigator.share({
        url: refLink
    })
    }
}   

  return (
    <div>
        
        <div className="content-header d-md-none" style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
          <h3 className='content-header-text'>
              Hi, {props.user.lastname} 👋
            </h3>
            <div className='vertical-line'> </div>
            <div className='text-right content-header-text'>
              <span style={{ display: 'flex', justifyContent: 'space-between', width: '140px' }}>
              <a href={"https://www.facebook.com/sharer/sharer.php?u="+refLink} style={{color : iconColor }}><span><i className='fab fa-facebook-f'></i></span></a>
              <a href={"whatsapp://send?text=Hello, register on "+ refLink +" to start buying cheap data and airtime today!"} style={{color : iconColor }}> <span><i className='fab fa-whatsapp'></i></span></a>
                <span><i className='fab fa-twitter'></i></span>                
                <span><i className='fab fa-instagram'></i></span>
                <a href="#" onClick={() => shareDialogue()} style={{color : iconColor }}> <span><i className='fa fa-link'></i></span></a>
              </span>
            </div>

        
      </div>

    </div>
  )
}

export default Welcome_Social