import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Nav from '../components/Nav'

function NotFound() {
  return (
    <div>    
        <Header/>
        <Nav/>
        <section className="error-page h-p100">
            <div className="container h-p100" style={{ background: 'linear-gradient(to right, #2c3e50, #2980b9)' }}>
            <div className="row h-p100 align-items-center justify-content-center text-center">
                <div className="col-lg-7 col-md-10 col-12">
                    <div>
                        <h1 className="text-white font-weight-900" style={{ fontSize: "100px" }}> 404</h1>
                        <h1 className="text-white" style={{ fontSize: "20px" }}><i className="fa fa-warning text-warning"></i> Page Not Found !</h1>
                        <h3 className="text-white" style={{ fontSize: "13px" }}>We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.</h3>
                        <div className="my-30"><Link to="/" className="btn btn-danger btn-rounded"><i className='fas fa-arrow-circle-left'></i> Go Back</Link></div>				  
                    
                    </div>
                </div>				
            </div>
            </div>
        </section>

    </div>
  )
}

export default NotFound