// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import error from '../../json/error.json';
import { ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import styles from '../../css/verify.module.css'
import { loadingReducer } from '../../reducers/loading-reducer';

function Verification() {

  const navigate = useNavigate();   
  // var CryptoJS = require("crypto-js");
  
  const [nin, setNIN] = useState('');
  const [loading, dispatch] = useReducer(loadingReducer, false);

  async function proceed(e) {
      e.preventDefault();        
      if (nin == "") return toaster("error", error.validation.requiredInput);
      if (nin.length !== 11) return toaster("error", "NIN must be 11 digit");

      if (!loading){
          dispatch({type: 'start'});   
          axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
          await axios.post(baseURL + "/user/kyc/verify",  {
              nin: nin,
        }).then((response) => {
          dispatch({type: 'stop'});
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {

              dispatch({type: 'stop'});
              setTimeout(() => {
                  navigate("/");
              }, 2000);
              return toaster("success", response.data.message);

            } else {
              return toaster("error", error.whoops);   
            }

    
          }).catch((e)=>{
              dispatch({type: 'stop'});
              if (e.response !== undefined) {
                  if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                      storage("remove", "data", null, "localStorage");
                      return navigate("/login");
                  }
                }
                  return toaster("error", error.loading.excemption);
          }) 
      }       
  }





  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '19px' }}> Verification </span> 
                        <span className='' style={{ marginLeft: '5px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                        <i>Kindly enter your national identification number (NIN) into the below field and submit.  </i>
                        </span>                      
                        </div>
                    </div>
                    <img src={"/images/fingerprint-verified.jpeg"} width={55} height={55} className='fw_img_shadow' style={{ borderRadius: '50%' }}/> 
                </div> 
                    



      <div className='mt-2 mb-0'>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={nin} onChange={(e) => setNIN(e.target.value)}
            placeholder='Please enter your NIN' className="form-control input-area" id="number" /> 
        </div>
    </div>



<div>

  <div className='flex'> 
    <div className={'box p-2 pr-3 pl-3 mb-3 ' + styles.nin_template} style={{ justifyContent: 'center', background: 'url(../images/nin-template.jpeg)' }}> 
            
    </div>

    <div style={{ width: '5%' }}></div>

    <div className='box p-2 text-center mb-3' style={{ display: "flex", justifyContent: "center" }}> 
    <div style={{ fontSize: "11px" }}><i> To check your NIN </i></div>
        <div><span style={{ fontSize: "11px" }}><i>dial</i></span> <span style={{ fontWeight: "bold", fontSize: "14px" }}>*346#</span></div>
      </div>
    </div> 

  </div>

<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
    { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loading ? '' : 'Submit' } </button>

      <div className='text-center mt-2 mb-2'>
        <p style={{ color: "red", fontWeight: "bold", fontSize: "13px" }}>Warning:- Please note that using another individual's NIN will result in the suspension of your account. </p>
        {/* <img src='/images/mastercard-visa-verve.png' width={'170'}/> */}
      </div>
          
</div>


              
            </div>
        </div>
        



       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default Verification