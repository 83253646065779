import React from 'react'
import { Link } from 'react-router-dom'

function Nav() {
  return (
    <div>
        
        <nav className="main-nav" role="navigation">

    {/* <!-- Mobile menu toggle button (hamburger/x icon) --> */}
    <input id="main-menu-state" type="checkbox"/>
    <label className="main-menu-btn" htmlFor="main-menu-state">
      <span className="main-menu-btn-icon"></span> Toggle main menu visibility
    </label>

    {/* <!-- Sample menu definition --> */}
    <ul id="main-menu" className="sm sm-blue">
      <li><Link to="/" className='nav-border'><i className="fab fa-windows"><span className="path1"></span><span className="path2"></span></i>Dashboard</Link></li>
      <li><Link to="/transactions" className='nav-border'><i className="fab fa-opencart"><span className="path1"></span><span className="path2"></span></i>Transactions</Link></li> 

      <li><Link to="/profile" className='nav-border'><i className="fas fa-user" style={{ fontSize: "15px" }}><span className="path1"></span><span className="path2"></span></i>Profile</Link></li>
      
      <li><Link to="/change-password" className='nav-border'><i className="fas fa-key" style={{ fontSize: "15px" }}><span className="path1"></span><span className="path2"></span></i>Change Password</Link></li> 
      <li><Link to="/reset-pin" className='nav-border'><i className="fas fa-lock" style={{ fontSize: "15px" }}><span className="path1"></span><span className="path2"></span></i>Reset PIN</Link></li> 
      <li><Link to="/referral/log" className='nav-border'><i className="fas fa-users" style={{ fontSize: "15px" }}><span className="path1"></span><span className="path2"></span></i>Referrals</Link></li> 
      {/* <li><a href={void(0)}><i className="icon-Chart-pie"><span className="path1"></span><span className="path2"></span></i>Account</a>
          <ul> 
              <li><Link to="/profile"><i className="fas fa-user"><span className="path1"></span><span className="path2"></span></i>Profile</Link></li>
              <li><Link to="/change-password"><i className="fas fa-key"><span className="path1"></span><span className="path2"></span></i>Change Password</Link></li> 
              <li><Link to="/reset-pin"><i className="fas fa-lock"><span className="path1"></span><span className="path2"></span></i>Reset PIN</Link></li> 
          </ul>
      </li> */}

 



 

    </ul>
  </nav>

    </div>
  )
}

export default Nav