import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import Tv from '../components/service/Tv';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import CableSkeleton from '../components/Skeleton/CableSkeleton';
import SetPIN from '../components/modals/SetPIN';

function Cable() {
  const navigate = useNavigate();
  var defaultPlanTxt = 'Select plan';
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [pinModal, setPinModal] = useState(false);
  const [wallet, setWallet] = useState('');
  const [isValidate, setIsValidate] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [planText, setPlanText] = useState(defaultPlanTxt)

    const [value, setValue] = useState({
          phoneNumber: '',
          cardNumber: '',
          img: '/dashboard/images/MultiChoice_logo.png',
          imgWidth: '35px',
          tv: [],
          plans: [],
          cable: '',
          cablePlans: [],
          selectedPlan: '',
    });

const fetch_plans = async () => {
    dispatch({type: 'start'});
    await axios.post(baseURL+"/cable/plans",  {
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
    if (response.data.status == "failed" && response.data.message !== "") {
        return toaster("error", response.data.message);
      } else if (response.data.status == "success") {
        // console.log(response.data);
        PINHandler(!response.data.pin);
          setValue({
            ...value,
            plans: response.data.plans,
            tv: response.data.cables,
          });
          setWallet(response.data.balance);
          dispatch({type: 'stop'});
        } else {
          return toaster("error", error.loading.excemption);    
        }

    }).catch((e)=>{
      if (e.response !== undefined) {
        if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
            storage("remove", "data", null, "localStorage");
            return navigate("/login");
        }
      }
        return toaster("error", error.loading.excemption);
        // return e;
    })
}



//  Plans action sheet
  const ref = useRef();

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    ref.current.close();
  }



    function phoneNumberHandler(e) {
      setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
    }


/**************************************************************************/

function tvHandler(cable) {
    // console.log(cable);
    tvImage(cable);
    setPlanText(defaultPlanTxt); 
    if (cable !== "") {
      var plans = value.plans.filter((e) => {
          return e.cable == cable
      })

      if (plans.length == 0) {
        toaster("error", error.service.notAvailable);
      }

      setValue({...value, cable: cable,  cablePlans: plans, selectedPlan: []});
    } else {
      setValue({...value, cable: '', cablePlans: [], selectedPlan: []});
    }
  }


/**************************************************************************/

function planHandler(id) {
    var plan_id = id;
    var plan = value.cablePlans.filter((e) => {
      return e.id == plan_id;
    });
    setValue({...value, selectedPlan: plan});
    setPlanText(plan[0].plan + ' - ₦' + plan[0].amount);
    // console.log(plan[0].plan + ' - ₦' + plan[0].amount);
    handleClose();
  }

  const [image, setImage] = useState({
    src: '/images/networks.jpg',
    borderColor: '#122614 #edc704 #17a00b #ed1d23',
    imageWidth: '37px',
    spanBorder: '1px solid',
    rotate: 'rotate',
  })
  
  /**************************************************************************/
  
  const tvImage = (tv) => {
          // console.log(network);
      switch (tv) {
        // GOTV
      case "GOTV": setImage({
          src: "/images/gotv.png",
          borderColor: '#f9c905',
          imageWidth: '35px',
          spanBorder: '2px solid',
          rotate: '',
      });
      
      break;
      // DSTV
      case "DSTV": setImage({
        src: "/images/dstv.png",
        borderColor: '#48b83e',
        imageWidth: '36px',
        spanBorder: '2px solid',
        rotate: '',
      });
      break;
  
      // STARTIMES
      case "STARTIMES": setImage({
        src: "/images/startimes.png",
        borderColor: '#ed1b24',
        imageWidth: '35px',
        spanBorder: '2px solid',
        rotate: '',
      });
      break;

        default: setImage({
          src: '/images/multichoice.jpg',
          borderColor: '#122614 #edc704 #17a00b #ed1d23',
          imageWidth: '37px',
          spanBorder: '1px solid',
          rotate: 'rotate',
        });
  
      }
  
  }  

  
/**************************************************************************/

function notValidated() {
    setValidateLoading(false);
    setIsValidate(false);
  }
  
  
  function validate() {
    setValidateLoading(true);
    axios.post(baseURL+"/cable/validate", {
      id: value.selectedPlan[0].cable_id,
      card_number: value.cardNumber,
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      },
    }).then((response) => {
        if (response.data.status == "success" && response.data.name !== "") {
          setCustomerName(response.data.name);   
          setValidateLoading(false);     
          setModalShow(true);
        } else if (response.data.status == "failed" && response.data.message !== "") {
          notValidated();
          return toaster("error", response.data.message);
        } else {
          notValidated();
          return toaster("error", error.whoops);  
        }
      }).catch((e)=>{
          notValidated();
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }      
          return toaster("error", error.whoops);
          // return e;
      })
  
  }


  function proceed(e) {
    e.preventDefault();
    if (!validateLoading) {
    if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
    if (value.cable == "") return toaster("error", error.validation.emptyDecoder);
    if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
    if (value.cardNumber == '' || value.cardNumber.length < 6) return toaster("error", error.validation.invalidDecoder);
    if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber); 
    if (wallet < value.selectedPlan[0].amount && value.selectedPlan[0].amount !== wallet) return toaster("error", error.lowBalance);
     
    if (isValidate == false) { 
        validate();
        setIsValidate(true);
      return;
    }

    if (customerName !== "") {
    let object = {
        "service": "Cable Subscription",
        "id" : value.selectedPlan[0].id,
        "tv" : value.cable,
        "number": value.phoneNumber,
        "amount": value.selectedPlan[0].amount,
        "plan": value.selectedPlan[0].plan,
        "customerName": customerName,
        "cardNumber": value.cardNumber,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }

  } else {
    return toaster("error", error.validation.emptyCustomerName);
  }
  }

}

  
  const PINHandler = (value) => {
    setPinModal(value);
  }
  
  const hideModal = () => {
    setModalShow(false);
    setIsValidate(false);
  }
  
  const btnTxt = isValidate === true ? "Proceed" : "Validate";
  const [validateLoading, setValidateLoading] = useState(false);
  
  useEffect(() => {
    scrollToTop();
    fetch_plans();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Cable Subscription'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}



    <SetPIN visibility={pinModal} state={PINHandler}/>


{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
            <img src="/images/Multichoice-banner.png" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            /> 
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "1px" }}>
        <i> Please double-check that the above name corresponds to the card holder's name. </i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '0px' }}>
                <tbody>
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Card Number</b> </td>
                        <td style={{ fontSize: '12px' }}> <span style={{ color: "blue" }}> <b>  {value.selectedPlan.length == 1 ? value.cardNumber : ''}  </b> </span> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{value.selectedPlan.length == 1 ? value.selectedPlan[0].amount : ''} </b></span> </td>
                     </tr>
                    <tr>
                        <th scope='row' style={{ fontSize: '12px' }}> Package </th>
                        <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "red"}}><b> {value.selectedPlan.length == 1 ? value.selectedPlan[0].plan : ''} </b> </span> </td>
                    </tr>
            </tbody>
        </table>


        <div className="row mb-0 mt-3 text-center">
          <div className="col-6">
          <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={hideModal} style={{ color: "white", backgroundColor: "red", fontSize: '14px', padding: "12px 16px" }}>Cancel </a>
          </div>
          <div className="col-6">
          <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={proceed} style={{ color: "white", backgroundColor: "green", fontSize: '14px', padding: "12px 16px" }}>Yes, it's correct</a>
          </div>
        </div>
   

      </Modal.Body>
    </Modal>








  <div className="col-xl-12 col-12">

    { loading ? <CableSkeleton/> : null }

      <div className="row">   
      { !loading ?  <Tv provider={tvHandler} /> : null }        
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>


    <div className='mt-3 mb-0' style={{ padding: '0px 0px' }}  hidden={ loading  }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.phoneNumber} onChange={phoneNumberHandler} 
            placeholder='Enter phone number' className="form-control input-area" id="number" /> 
        </div>
    </div>



    <div className='mt-0 mb-2' style={{ padding: '0px 0px' }} hidden={ value.cablePlans.length !== null && value.cablePlans.length > 0 ? false : true }>
        <div className='box mb-0' onClick={handleOpen} style={{ cursor: 'context-menu', background: '#fff' }}>
            <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                <span> <img src={image.src} width={30} style={{ borderRadius: '50%' }}/> </span>
                <span className='select_list_text'> {planText} </span>
                <span className='select_list_text'> <i className="fa fa-chevron-right"></i> </span>
            </div>           
        </div>
    </div>



<>
    <ActionSheet ref={ref}>
      <div style={{ height: value.cablePlans.length * 100 + 'px'}} className='actionsheet_'>
        { value.cablePlans.length !== 0 ? value.cablePlans.map((e, index) => { 
            return <div className='mt-0 mb-2' key={index} style={{ padding: '0px 0px' }}>
            <div className='box mb-0' onClick={() => planHandler(e.id)} style={{ cursor: 'context-menu', background: '#fff', padding: '0px 5px', overflow: 'auto' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <img src={image.src} width={30} style={{ borderRadius: '50%' }}/> </span>
                    <span className='select_list_text'> { e.plan } { value.selectedPlan.length !== 0 && value.selectedPlan[0].id == e.id ? <i className='fas fa-check text-success'></i>  : '' } </span>
                    <span className='select_list_text'> &#8358;{ e.amount } </span>
                </div>           
            </div>
        </div>
        }) : ''}
      </div>
    </ActionSheet>
  </>


  <div className='mt-1 mb-0' style={{ padding: '0px 0px' }}  hidden={ value.selectedPlan.length > 0 ? false : true }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
            value={value.cardNumber} onChange={(e) => setValue({...value, cardNumber: e.target.value})}
            placeholder='Enter IUC / Card Number' className="form-control input-area" id="number" /> 
        </div>
    </div>



<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
    { validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
        { validateLoading ? '' : btnTxt }
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Cable