import React from 'react'
import { useNavigate } from 'react-router-dom'

function Top (props) {
  const navigate = useNavigate();

  function ServiceImg(props) {
    let serviceImg = null;
    let service = props.service;
    if (service == "Data Topup" || service == "Airtime Topup" || service == "Recharge Card") {
      serviceImg= "/images/networks.png";
    } else if (service == "Cable Subscription") {
      serviceImg= "/images/streaming-tv-app.png";
    } else if (service == "Utility Bills") {
      serviceImg= "/images/bulb.png";
    } else if (service == "Result Checker") {
      serviceImg= "/images/exam.png";
    } else if (service == "Smile") {
      serviceImg= "/images/smile.png";
    } else if (service == "Bulk SMS") {
      serviceImg= "/images/sms.png";
    } else if (service == "Spectranet") {
      serviceImg= "/images/spectranet-device.png";
    }
    
    
    return (
      <>
        <img src={serviceImg} style={{ borderRadius: "50%" }} width="15" />
      </>
    )
  
  }

  return (
    <div>
      {/* <!-- Content Header (Page header) -->	   */}
      <div className='content-header go_back_style' style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', padding: '5px 15px 0px' }}>
        <h3 className=''><div className='go_back' onClick={() => navigate("/")}> <i className='fas fa-arrow-left'></i> Go Back </div></h3>
        <div className='vertical-line'>
        </div>
        <div className='text-right content-header-text'>
          <div className='font_lobster'>
            {props.title} <ServiceImg service={props.title}/>
            {/* <img style={{ borderRadius: '50%' }} width={15} src='/images/networks.png' /> */}
          </div>
          {/* <div>  Balance = 1000 </div> */}
        </div>
      </div>
    </div>
  )
}

export default Top
