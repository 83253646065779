import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams   } from "react-router-dom";
import axios from 'axios';
import { auth, toaster, baseURL, siteLogo } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';

function ResetPassword() {

    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const token = searchParams.get("token") !== null ? searchParams.get("token") : '';
    const email = searchParams.get("email") !== null ? searchParams.get("email") : '';
    const [loading, setLoading] = useState(false);    

    const navigate = useNavigate();
    const formHandler = async (event) => {
      event.preventDefault();
      if (!loading) {
      if (email == "" || token == "" || password == "" || confirmPassword == "")  return toaster("error", "Please fill all required fields");
      if (email.length < 3)  return toaster("error", "Invalid email supplied ");
      if (password.length < 2)  return toaster("error", "Invalid password supplied ");
      if (password !== confirmPassword) return toaster("error", "Passwords do not macth");
      setLoading(true);
      axios.defaults.headers.common = {'Content-Type': 'application/json'}
      await axios.post(baseURL+"/user/reset-password",  {
            email: email, 
            token: token, 
            password: password, 
            confirm_password: confirmPassword
        }).then((response) => {
          if (response.data.code !== 200 && response.data.status == "failed") {
            setLoading(false);
            return toaster("error", response.data.message);
          } else if (response.data.code == 200 && response.data.status == "success") {
            setLoading(false);
            return toaster("success", response.data.message);
          } else {
            setLoading(false);
          }
        }).catch((e)=>{
            // console.log(e);
            setLoading(false);
            alert(error.whoops)
            return e;
        })
      }
    }


      useEffect(() => {         
        if (auth() == true) return navigate("/")                    
            if(token == "" || token == undefined || email == "" || email == undefined) return navigate('/forgot-password')
        } , 
                    
        []);

  return (
    <div>
        <ToastContainer/>
        <div className='pt-5 mb-5' style={{ marginTop: '50px' }}>
            <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>
        {/* <div className="auth-2-outer row align-items-center h-p100 m-0"> */}

		<div className="auth-card bg-white">
		  <div className="auth-logo font-size-30 pt-4">
			<img src={siteLogo} width={150}/>
			{/* <a href="../index.html" className="text-dark"><b>Telecom</b>plug</a> */}
		  </div>
          
		  <div className="auth-body" style={{ padding: '0px 12px 10px' }}>
            <h2 className='text-center' style={{ fontSize: '20px', fontWeight: '600' }}> Reset Password </h2>
			<p className="auth-msg text-black-50"><i>Set new password for your account.</i></p>

			<form method="post" className="form-element" onSubmit={formHandler}>
            
            <div className="form-group has-feedback">
				<input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control login-input" placeholder="Enter new password"/>
				<span className="fas fa-key form-control-feedback text-dark mt-2"></span>
			  </div>


              <div className="form-group has-feedback">
				<input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className="form-control login-input" placeholder="Confirm your new password"/>
				<span className="fas fa-key form-control-feedback text-dark mt-2"></span>
			  </div>
			 
			  <div className="row">
                               

				<div className="col-12 text-center mt-1">
				<button className='btn btn-primary mb-4 tp_btn'>
				{ loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 				{ loading ? '' : 'Reset ' }
				{ loading ? '' : <i className='fas fa-circle-arrow-right'></i> }
      			</button>
            
				</div>
                
			  </div>
			</form>


			<div className="margin-top-30 text-center text-dark">
				<p>Do you remember your password? <Link to="/login" className="text-info m-l-5">Login</Link></p>
			</div>
			
			<div className='mb-3' style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
				<img src='/images/google_play_btn.png' width={150} height={65}/>
				<img src='/images/app-store-logo-transparent.png' width={150} height={45}/>
			</div>

		  </div>
		</div>
	
	</div> </div> </div> 
    </div>

    </div>
  )
}

export default ResetPassword