// import React from 'react'

import axios from 'axios';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseURL, user, formatDate, storage, toaster, siteLogo, skeletonBaseColor } from '../functions/O3developer';
import Header from '../components/Header';
import Nav from '../components/Nav';
import error from '../json/error.json';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useReducer } from 'react';
import { loadingReducer } from '../reducers/loading-reducer';
import NoResultFound from './NoResultFound';
import { PulseLoader } from 'react-spinners';

function Transactions() {

    const navigate = useNavigate();
    const [history, setHistory] = useState([]);  
    const [pagination, setPagination] = useState({
      next_page_url: '',
      prev_page_url: '',
    });
  
    const [entries, setEntries] = useState('');
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(true);
    const [loading, dispatch] = useReducer(loadingReducer, false);
  
    async function load(URL) {
      if (URL !==  null) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
      dispatch({type: 'start'});
      await axios.get(URL,  {
        }).then((response) => {
          dispatch({type: 'stop'});
          // console.log(response.data);
          setHistory(response.data.data);
          setPagination({...pagination, 
            next_page_url: response.data.next_page_url,
            prev_page_url: response.data.prev_page_url,
          });
        if (response.data.code == 500) {
          return toaster("error", response.data.message);
            // return toaster("error", response.data.message);
        } else if (response.data.code == 200) {
            // console.log(response.data);
        }
  
        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            
          return toaster("error", error.loading.excemption);
        })
      }
    }
  
  
    const viewReceipt = (id) => {
        let transaction = history.find((e) => e.id == id);
        // console.log(transaction);
        if(storage("save", "receipt", transaction, "sessionStorage")) {
          navigate("/receipt");
        } else {
            // return alert(error.whoops);
        }
    }
    
  
    const go = (e) => {
      e.preventDefault();
      load(baseURL + "/transaction?search="+search+"&entries="+entries);
    }
  
    const filterState = (event) => {
      event.preventDefault();
      // setFilter(!filter);
    }
  
    const description = (desc) => {
        return desc.toString().substring(0, 30) + '...';
    }
  
  
    useEffect(() => {
      load(baseURL+"/transaction?search="+search+"&entries="+25);    
    }, []);
  
  
  function TranxStatus(props) {
    let badgeClass = null;
    switch(props.status) {  
      case "success": badgeClass = "bg-green";
      break;
      case "reversed": badgeClass = "bg-red";
      break;
      case "cancel": badgeClass = "bg-red";    
      break;
      case "pending": badgeClass = "bg-warning";
       break;
      case "initiated": badgeClass = "bg-warning";
      break;
      default: badgeClass = "bg-warning";
    }
    return (
      <>
        <span className={"mt-0 badge border-0 " + badgeClass}>{props.status}</span>
      </>
    )
  
  }
  
  
  function Item(props) {
      // console.log(props.data);    
      let imgBaseURL = "/images/";
      let tranxImg = siteLogo;
      const provider = props.data.provider;
      const service = props.data.service;
      if (provider == "MTN") {
        tranxImg = imgBaseURL + "mtn-logo.jpg";
      } else if (provider == "GLO") {
        tranxImg = imgBaseURL + "glo-logo.jpeg";
      } else if (provider == "AIRTEL") {
        tranxImg = imgBaseURL + "airtel-logo.webp";      
      } else if (provider == "9MOBILE") {
        tranxImg = imgBaseURL + "9mobile-logo.png";
      } else if (service == "Bills Payment") {
        tranxImg = imgBaseURL + "bulb.png";
      } else if (service == "Result Checker") {
        tranxImg = imgBaseURL + "graduation-cap.png";
      } else if (service == "Cable Subscription") {
        tranxImg = imgBaseURL + "multichoice.jpg";
      } else if (service == "Wallet Funding") {
        tranxImg = imgBaseURL + "wallet-icon.png";
      } else if (service == "Spectranet") {
        tranxImg = imgBaseURL + "spectranet-device.png";
      } else if (service == "SMS") {
        tranxImg = imgBaseURL + "sms-icon.png";
      } else if (service == "Referral") {
        tranxImg = imgBaseURL + "exchange.png";
      }
  

      return (
        <>
          <div className='mt-0 mb-2' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu' }} onClick={() => viewReceipt(props.data.id)}>
                <div className='flex justify-content-sb' style={{ height: '55px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={tranxImg} width={35} height={35} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='tranx-header-txt' style={{ marginLeft: '5px' }}> {props.data.service} </span> 
                        <span className='tranx-desc-txt' style={{ marginLeft: '5px' }}> { description(props.data.description) } </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                        
                        <div className='flex' style={{ flexDirection: 'column', alignItems: 'flex-end' }}>  
                        <span className='tranx-header-txt'> ₦{Math.round(props.data.amount * 100) / 100} </span>
                        <span className='select_list_text'><TranxStatus status={props.data.status}></TranxStatus></span>                       
                        </div>
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px' }}></i>
                    </div>


                </div> 

                <div className='text-center pt-1 pb-1' style={{ fontSize: '8px', marginTop: '-10px' }}>
                    <span> Date: {formatDate(props.data.date)} | </span>
                    <span>Ref: {props.data.reference} | </span>
                    <span>Tel: {props.data.phone_number}</span>
                </div> 

            </div>
        </div>

        </>
      )
    }
  







  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>

{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '15px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          <ToastContainer/>




    <div className='flex mb-2' style={{ alignItems: 'center' }}>
      <input type="text" autoCorrect='off' autoComplete='off' value={search} maxLength="100" onChange={(e) => setSearch(e.target.value)}        
          placeholder="Enter phone number or reference" className="form-control search-input mr-3" id="search_input" /> 

      <a href="#" className="btn search-btn" onClick={(e) => go(e)}> Search <i className='fas fa-search'></i> </a>
    </div>



        {/* -------------------------------------- */}

        { history.length !== 0 && !loading ? history.map((data, index) => {
        return <Item key={index} data={data} />
        }) : '' }

        {/* ------------------------------------ */}

        {history.length == 0 && !loading ? <div className='mt-4'><NoResultFound/></div> : null }

        <div hidden={!loading}>
            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>
            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>
        </div>

        <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>

          </div>
                        
        </div>						
    </section>
    
    <div className='text-center' hidden={loading}>
      <a href="#" hidden={pagination.prev_page_url == null ? true : false} onClick={() => load(pagination.prev_page_url) } className="btn pagination-btn mr-1" style={{ background: 'white', color: '#2c3e50' }}> <i className='fas fa-angle-left'></i> Previous </a>
      <a href="#" hidden={pagination.next_page_url == null ? true : false} onClick={() => load(pagination.next_page_url) } className="btn pagination-btn ml-1"> Next <i className='fas fa-angle-right'></i> </a>
    </div>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default Transactions