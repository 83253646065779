import React, { useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import Switch from 'react-switch';
import ValidateCard from '../components/service/ValidateCard';
import SetPIN from '../components/modals/SetPIN';

function BulkSMS() {
  const navigate = useNavigate();

  const fetch_SMS = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/bulk-sms",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
          PINHandler(!response.data.pin);
          setCharge(response.data.data.charge);
          setWallet(response.data.balance);
            dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
        
      return toaster("error", error.loading.excemption);
          // return e;
      })
  }

/**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [proceedLoading, dispatchProceedLoading] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState('');
    const [charge, setCharge] = useState('');
    const [toggle, setToggle] =  useState(false);
    const [validateBulkNumber, setValidateBulkNumber] = useState(true);
    const btnTxt = validateBulkNumber === true ? "Proceed" : "Validate"
      const [value, setValue] = useState({
            img: '/dashboard/images/email.png',
            imgWidth: '35px',

            hideResult: true,
            validNumbers: '',
            InvalidNumbers: '',
            duplicateNumbers: '',
            senderName: '',
            phoneNumber: '',
            message: '',
            validate: {
              number: true,
            },
      });
   

/**************************************************************************/

const toggleHandler = (checked) => {
  let newValidateBulkNumber = !validateBulkNumber;
  // console.log(toggle);
    setValue({...value, hideResult: true, phoneNumber: ''});      
    setToggle(checked);
    // setValidateBulkNumber(newValidateBulkNumber);
}




function validateTextArea(result) {
  var arr = []; // New array of sanitized numberArray
  var InvalidNumber = []; // Array of invalid number(s) excluded
  var numbersArray = result.split(','); // Turn uploaded string to array
      //   Remove white space
  for (let i = 0; i < numbersArray.length; i++) {
      let number = numbersArray[i].trim()
          if (number !== "" && !isNaN(number)) {
            if (number.length == 11) {
                arr.push(number);
            } else {
                InvalidNumber.push(number);
            }
        }
    }
      const unique = Array.from(new Set(arr)); // Remove duplicate numbers
      var phoneNumber = unique.toString();
       // Duplicate numbers **** Subtract unique from total numbers
      let count = arr.length - unique.length;
      setValue({
        ...value,
        phoneNumber: phoneNumber,
        hideResult: false,
        duplicateNumbers: count,
        validNumbers: unique.length,
        InvalidNumbers: InvalidNumber.length,
      });
}



function phoneNumberHandler(e) {
  // validateTextArea(e.target.value);
  setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
}


    /**************************************************************************/
    
    function proceed(e) {
      e.preventDefault();
      if (value.senderName == "") return toaster("error", error.validation.emptySenderName); 
      if (value.message == "") return toaster("error", error.validation.emptyMessage);       
      if (toggle == false && value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);      
      if (validateBulkNumber === false) {
        validateTextArea(value.phoneNumber);
        setValidateBulkNumber(true);
        return;
    }

    // console.log(value);

    Swal.fire({
      title: 'Are you sure?',
      text: "Before you proceed, please double-check the transaction you're about to make.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatchProceedLoading({type: 'start'});
        axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
        axios.post(baseURL+"/bulk-sms/send", {
          "service": "Bulk SMS",
          "sender": value.senderName,
          "phoneNumber": value.phoneNumber,
          "message": value.message,
      }).then((response) => {
        
          dispatchProceedLoading({type: 'stop'});
          if (response.data.code == 200) {
            // console.log(response);
            return storage("save", "receipt", response.data, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
          } else if(response.data.code !== 200 && response.data.status == "failed" && response.data.message !== "") {
              // dispatch({type: 'stop'});
              // console.log('test');
              return toaster("error", response.data.message);
          } else {
            return alert(error.whoops);
          }

        }).catch((e)=> {

          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }

        })

        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })

         
  }
   
  

  const PINHandler = (value) => {
    setPinModal(value);
  }


  useEffect(() => {
    scrollToTop();
    fetch_SMS();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Bulk SMS'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}


    <SetPIN visibility={pinModal} state={PINHandler}/>


  <div className="col-xl-12 col-12">



 
  <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={loading}>
        <div className="form-group mb-2">
          <input type="text" maxLength={12} autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.senderName} onChange={(e) => setValue({...value, senderName: e.target.value})}
            placeholder='Enter sender name' className="form-control input-area" id="number" /> 
        </div>
    </div>


    <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={loading || toggle}>
          <div className="form-group mb-2">
            <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
            value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} 
              placeholder='Enter recepient phone number' className="form-control input-area" id="number" /> 
          </div>
      </div>


      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>



    {/* textarea */}
    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }} hidden={loading}>    
					<div className="form-group mb-2">
					  {/* <label>*</label> */}
					  <textarea className="form-control text-area" style={{ height: '105px' }} rows="3" value={value.message} onChange={(e) => setValue({...value, message: e.target.value})} placeholder="Enter your message here ..."></textarea>
					</div>
    </div>



    {/* textarea */}
    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }} hidden={!toggle}>    
					<div className="form-group mb-2">
					  {/* <label>*</label> */}
					  <textarea className="form-control text-area" style={{ height: '105px' }} rows="3" value={value.phoneNumber} onChange={phoneNumberHandler} onKeyUp={() => setValidateBulkNumber(false)} placeholder="Enter phone numbers seperated by comma (,) e.g 080********, 090********,070********,081********"></textarea>
					</div>
    </div>


    <div className='toggle_div mb-3' hidden={ loading }>
        <div className='font_nunito' style={{ fontSize: '15px' }}> 
          Bulk phone number <i className="fas fa-hand-point-right"></i>
          {/* <img width={20} src='/images/backhand-index-pointing-right-joypixels.gif'></img>  */}
        </div>        
        <Switch onChange={toggleHandler} checked={toggle} uncheckedIcon={false} checkedIcon={false} />           
    </div>

{/* Loader */}
    <div className='toggle_div mb-3'  hidden={ !loading }>
        <span className='skeleton_shadow' style={{ width: '50%' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
        <span className='skeleton_shadow' style={{ width: '55px' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
      </div>              
{/* Loading ends here */}

<ValidateCard hideResult={value.hideResult} validNumbers={value.validNumbers} InvalidNumbers={value.InvalidNumbers} duplicateNumbers={value.duplicateNumbers}/>

<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

      <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
    { proceedLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
    { proceedLoading ? '' : btnTxt }
      </button>


      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default BulkSMS
