import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import SetPIN from '../components/modals/SetPIN';

function Electricity() {
  const navigate = useNavigate();

/**************************************************************************/
const [loading, dispatch] = useReducer(loadingReducer, false);
const [pinModal, setPinModal] = useState(false);
const [wallet, setWallet] = useState('');
const [isValidate, setIsValidate] = useState(false);
const [customerName, setCustomerName] = useState('');
const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState({
        phoneNumber: '',
        meterNumber: '',
        type:'',
        img: '/dashboard/images/bulb.png',
        imgWidth: '35px',
        bills: [],
        amount: '',
        disco: '',
        selected_disco: [],
  });





    const fetch_bills = async () => {
        dispatch({type: 'start'});
        await axios.post(baseURL+"/bills",  {
      }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          },
        }).then((response) => {
        if (response.data.status == "failed" && response.data.message !== "") {
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {
            // console.log(response.data);
            PINHandler(!response.data.pin);
                setValue({
                  ...value,
                  bills: response.data.bills,
                });
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
  
        }).catch((e) => {
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            return toaster("error", error.loading.excemption);
            // return e;
        })
    }


    function phoneNumberHandler(e) {
      setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
    }


/**************************************************************************/

function billsHandler(e) {
    var disco = e.target.value;
    document.getElementById("type").selectedIndex = 0;
    if (disco !== "") {
      var bills = value.bills.filter((e) => {
        return e.disco == disco;
    })
    // console.log(bills);
        setValue({...value, disco: disco, selected_disco: bills});
    } else {
      setValue({...value, disco: '', meterNumber: '', type: '', amount: '', selected_disco: []});
    }
  }

  
/**************************************************************************/

function notValidated() {
    setValidateLoading(false);
    setIsValidate(false);
  }
  
  
  function validate() {
    setValidateLoading(true);
    axios.post(baseURL+"/bills/validate", {
      id: value.selected_disco[0].id,
      meter_number: value.meterNumber,
      type: value.type
    },{    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
        if (response.data.status == "success" && response.data.name !== "") {
          setCustomerName(response.data.name);
          setModalShow(true);
          setValidateLoading(false);
        } else if (response.data.status == "failed" && response.data.message !== "") {
          notValidated();
          return toaster("error", response.data.message);
        } else {
          notValidated();
          return toaster("error", error.whoops);  
        }
  
      }).catch((e)=>{
        notValidated();
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
        return toaster("error", error.whoops);
          // console.log(e);
      })
  
  }
  
  
  
  function proceed(e) {
    e.preventDefault();
    if (value.disco == "") return toaster("error", error.validation.emptyDisco);
    if (value.type == '') return toaster("error", error.validation.emptyType);
    if (value.selected_disco.length !== 1) return toaster("error", error.validation.emptyDisco);
    if (value.meterNumber == '' || value.meterNumber.length < 6) return toaster("error", error.validation.invalidDisco);
    if (value.amount == '' || value.amount < 1) return toaster("error", error.validation.emptyAmount);
    if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
    if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
    if (value.amount < 1000) return toaster("error", "The amount entered falls short of the required minimum (₦1000).");
    if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);
  
    if (isValidate == false) { 
      validate();
      setIsValidate(true);
      return;
    }
  
    if (customerName !== "") {
    let object = {
        "service": "Bills Payment",
        "id" : value.selected_disco[0].id,
        "disco" : value.disco,
        "number": value.phoneNumber,
        "type": value.type,
        "amount": value.amount,
        "customerName": customerName,
        "meterNumber": value.meterNumber,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }
    } else {
      return toaster("error", error.validation.emptyCustomerName);
    }
  
  }
  
  const PINHandler = (value) => {
    setPinModal(value);
  }
  
  const hideModal = () => {
    setModalShow(false);
    setIsValidate(false);
  }
  
  const btnTxt = isValidate === true ? "Proceed" : "Validate";
  const [validateLoading, setValidateLoading] = useState(false);
  
  
  useEffect(() => {
    scrollToTop();
    fetch_bills();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Utility Bills'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}


        <SetPIN visibility={pinModal} state={PINHandler}/>


{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}>
        <img src="/images/electricity-power-line.jpg" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            />  
          {/* <img src="/dashboard/images/ibedc-electricity.png" width={"50px"} />  */}
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "0px" }}>
        <i> Please double-check that the above name corresponds to the customer's name. ⚠️</i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '1px' }}>
                <tbody>
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Disco: <span style={{ color: "blue" }}> {value.disco !== '' ? value.disco : ''}  </span>  </b> </td>

                        <td style={{ fontSize: '12px' }}> <b> {value.type !== '' ? value.type : ''} </b> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{value.amount.length > 1 ? value.amount : ''} </b></span> </td>
                     </tr>
                    <tr>
                        <th scope='row' style={{ fontSize: '12px' }}> Meter Number </th>
                        <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "red"}}><b> { value.meterNumber !== '' ? value.meterNumber : '' } </b> </span> </td>
                    </tr>
            </tbody>
        </table>

        <div className="row mb-0 mt-3 text-center">
        <div className="col-6">
        <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={hideModal} style={{ color: "white", backgroundColor: "red", fontSize: '14px', padding: "12px 16px" }}>Cancel </a>
        </div>
        <div className="col-6">
        <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={proceed} style={{ color: "white", backgroundColor: "green", fontSize: '14px', padding: "12px 16px" }}>Yes, it's correct</a>
        </div>
      </div>

      </Modal.Body>

    </Modal>









  <div className="col-xl-12 col-12">



    <div className="form-group styled-select mb-2" hidden={loading}>
        <select id="disco" onChange={billsHandler} value={value.disco} className="form-control select-input">
        <option value=""> Please select  </option>
        { value.bills.length !== 0 ? value.bills.map((e, index) => {        
          return <option value={e.disco} key={index}> {e.disco} </option> 
          }) : ''}
        </select>
    </div>


    <div className="form-group styled-select mb-2" hidden={value.disco == '' || loading ? true : false}>
        <select id="type" onChange={(e) => setValue({...value, type: e.target.value, meterNumber: ''})} className="form-control select-input">
        <option value=""> Select meter type</option>
        <option value="PREPAID"> PREPAID </option>
        <option value="POSTPAID"> POSTPAID </option>
        </select>
    </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-1 mb-0' style={{ padding: '0px 0px' }}  hidden={value.type == '' || loading ? true : false}>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
            value={value.meterNumber} onChange={(e) => setValue({...value, meterNumber: e.target.value})}
            placeholder='Enter meter number' className="form-control input-area" id="number" /> 
        </div>
    </div>



    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }}  hidden={value.meterNumber == '' || loading ? true : false}>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
            value={value.amount} onChange={(e) => setValue({...value, amount: e.target.value})}
            placeholder='Enter amount' className="form-control input-area" id="number" /> 
        </div>
    </div>


    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }}  hidden={ loading  }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} 
            placeholder='Enter phone number' className="form-control input-area" id="number" /> 
        </div>
    </div>






{/* 

  <div className='mt-1 mb-0' style={{ padding: '0px 0px' }}  hidden={ value.selectedPlan.length > 0 ? false : true }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
            value={value.cardNumber} onChange={(e) => setValue({...value, cardNumber: e.target.value})}
            placeholder='Enter IUC / Card Number' className="form-control input-area" id="number" /> 
        </div>
    </div> */}



<div className='mt-0 mb-2' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
    { validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
        { validateLoading ? '' : btnTxt }
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>


  <div className='bg-white text-center p-1'>
      <img src="/images/ikeja-electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/ibedc-logo.png" className="rounded-radius" width="30"/>
      <img src="/images/jos-electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/port-harcourt.jpg" className="rounded-radius" width="30"/>

      <img src="/images/Abuja-Electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/Kaduna-Electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/eko-electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/Kano-Electric.jpg" className="rounded-radius" width="30"/>
      <img src="/images/EEDC-Logo.jpeg" className="rounded-radius" width="30"/>

  </div>

  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Electricity