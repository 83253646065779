import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
// import Carousel from 'nuka-carousel/lib/carousel'
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import Networks from '../components/service/Networks';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import NetworkSkeleton from '../components/Skeleton/NetworkSkeleton';
import { PulseLoader } from 'react-spinners';
import SetPIN from '../components/modals/SetPIN';

function RechargePin() {
  const navigate = useNavigate();
  const [pinModal, setPinModal] = useState(false);
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [wallet, setWallet] = useState('');
    const [value, setValue] = useState({
          img: '/dashboard/images/printer.png',
          imgWidth: '35px',
          recharge: [],
          range: [],
          network: '',
          selectedNetwork: [],
          selectedRange: '',
          quantity: '',
          amount: '',
          name: ''
    });

  const fetch_pin = async () => {
    dispatch({type: 'start'});
    await axios.post(baseURL+"/recharge-pin",  {
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
            // console.log(response.data);
            PINHandler(!response.data.pin);
            setValue({
              ...value,
              recharge: response.data.recharge,
              range: response.data.range,
            });
            setWallet(response.data.balance);
            dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }



//  Plans action sheet
  const ref = useRef();

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    ref.current.close();
  }



    function phoneNumberHandler(e) {
      setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
    }

 


/**************************************************************************/


function networkHandler(network) {
    if (network !== "") {
      var networks = value.recharge.filter((e) => {
          return e.network == network
      });  
        if (networks.length == 0) {
          toaster("error", error.service.notAvailable);
        }
      setValue({...value, network: network,  selectedNetwork: networks, selectedRange: '', quantity: ''});
    } else {
      setValue({...value, network: '', selectedNetwork: [], selectedRange: '', quantity: ''});      
    }
  }



/**************************************************************************/


function rangeHandler(e) {
    let range = e.target.value;
    if (range !== '') {                        
        setValue({...value, selectedRange: range, amount: '', quantity: ''});       
    } else {
        setValue({...value, selectedRange: '', amount: '', quantity: ''});
    }
    }


      function quantityHandler(e) {
        if (value.selectedRange !== '') {
            let quantity = e.target.value;  
            if (quantity < 61) {
              let price = value.selectedRange;
              let amount = price * quantity;
              quantity !== '' ? setValue({...value, quantity: quantity, amount: amount}) : setValue({...value, quantity: '', amount: ''});
            }
        } 
      }


    /**************************************************************************/
    
    function proceed(e) {
      e.preventDefault();
      if (value.selectedNetwork.length !== 1) return toaster("error", error.validation.emptyNetwork);
      if (value.selectedRange == "") return toaster("error", error.validation.emptyQuantity);
      if (value.quantity == "") return toaster("error", error.validation.emptyQuantity);
      if (value.name == "") return toaster("error", error.validation.emptyCardName);      
      if (value.quantity == "" || value.quantity == 0) return toaster("error", error.validation.emptyQuantity);
      if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);

      let object = {
          "service": "Recharge Pin",
          "id" : value.selectedNetwork[0].id,
          "network" : value.selectedNetwork[0].network,
          "quantity": value.quantity,
          "amount": value.amount,
          "range": value.selectedRange,
          "name": value.name,
        };
      object = JSON.stringify(object);
      if(storage("save", "invoice", object, "sessionStorage")) {
          navigate("/invoice");
      } else {
          return alert(error.whoops);
      }
    
    }
  

    const PINHandler = (value) => {
      setPinModal(value);
    }


    useEffect(() => {
        fetch_pin()
    }, [])


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Recharge Card'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}


{/* ====================================================================== */}


<SetPIN visibility={pinModal} state={PINHandler}/>


  <div className="col-xl-12 col-12">

    { loading ? <NetworkSkeleton/> : null }

      <div className="row">   
      { !loading ?  <Networks provider={networkHandler}/> : null }        
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>




    <div className="form-group styled-select mb-2 mt-3" hidden={ value.selectedNetwork.length == 1 ? false : true }>
    <select id="type" onChange={rangeHandler} value={value.selectedRange} className="form-control select-input">
      <option value=""> Select range</option>
        { value.selectedNetwork.length == 1 ? value.range.map((e, index) => {
          if (e.id == value.selectedNetwork[0].id) {
            return <option value={e.range} key={index}> &#8358;{e.range} </option>
          }
        }) : '<option value=""> Service currently unavailable </option>'  }
    </select>
    </div>



    <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={ value.selectedRange !== '' ? false : true }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' value={value.quantity} onChange={ quantityHandler } id="quantity"
          placeholder="Type qunatity [max 60]" className="form-control input-area" /> 
        </div>
    </div>


    <div className='mt-3 mb-0' style={{ padding: '0px 0px' }} hidden={ value.quantity !== '' ? false : true }>
        <div className="form-group mb-2">
          <input type="text" value={'₦'+value.amount} onChange={ (e) => {setValue({...value, amount: e.target.value})} } id="amount"
          readOnly placeholder="Enter amount" className="form-control input-area" /> 
        </div>
    </div>


    <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={ loading  }>
        <div className="form-group mb-2">
          <input type="text" autoCorrect='off' autoComplete='off' maxLength={20}  value={value.name} 
          onChange={(e) => setValue({...value, name: e.target.value})} id="name"
          placeholder="Name on card [E.g your name or bussiness name]" className="form-control input-area" /> 
        </div>
    </div>



    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
        Proceed <i className='fas fa-circle-arrow-right'></i>
    </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>




                </div>
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



{/* <footer className="main-footer">
<div className="pull-right d-none d-sm-inline-block">
    <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
      <li className="nav-item">
        <a className="nav-link" href="#">FAQ</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Purchase Now</a>
      </li>
    </ul>
</div>
  &copy; 2020 <a href="../../../index.htm">Multi-Purpose Themes</a>. All Rights Reserved.
</footer> */}


</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default RechargePin