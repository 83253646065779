import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { skeletonBaseColor } from '../../functions/O3developer'

function NetworkSkeleton() {
  return (
    <div>
        
    <div style={{ margin: '0px 10px' }}>
        <div className='row'>
          
            <div className='col-3 col_padding skeleton_shadow'><SkeletonTheme height={70} baseColor={skeletonBaseColor}> <Skeleton count={1} /> </SkeletonTheme></div>
            <div className='col-3 col_padding skeleton_shadow'><SkeletonTheme height={70} baseColor={skeletonBaseColor}> <Skeleton count={1} /> </SkeletonTheme></div>
            <div className='col-3 col_padding skeleton_shadow'><SkeletonTheme height={70} baseColor={skeletonBaseColor}> <Skeleton count={1} /> </SkeletonTheme></div>
            <div className='col-3 col_padding skeleton_shadow'><SkeletonTheme height={70} baseColor={skeletonBaseColor}> <Skeleton count={1} /> </SkeletonTheme></div>

        </div>
    </div>

    </div>
  )
}

export default NetworkSkeleton