import React, { useRef, useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';

function Invoice() {
  
const navigate = useNavigate();

function invoice() {
  let data = storage("retrieve", "invoice", null, 'sessionStorage');
  if (data && typeof(data) === "object") {    
        setDetails(data);
        // console.log(data);
        data.number && setNumberArray(data.number.split(','));
        // console.log(data.number.split(','));  
  } else {
    navigate("/");
  }

}

  const [details, setDetails] = useState({});
  const [pinIcon, setPinIcon] = useState(true);
  const [inputPin, setInputPin] = useState('');
  const [verifyModal, setVerifyModal] = useState(false);
  const [numberArray, setNumberArray] = useState([]);
  const [loading, dispatch] = useReducer(loadingReducer, false);

  const sendRequest = async (payload, path) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
    let response = await axios.post(baseURL+path, payload)
      .then((response) => {
          return response.data;
      }).catch((e)=>{

        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }

          // console.log(e);
          return e;
      })
      return response;
  }

  function pinInputHandler(value) {
    var pinLength = value.length;
    var pinDOM = document.getElementById('input_pin');
    if(pinLength < 5) {
      setInputPin(value);
      if(pinLength === 4) { pinDOM.blur() }

    } else {
      pinDOM.blur();
    }
  }



  const proceed = async (e) =>  {
    e.preventDefault();
    if (!loading) {
      let pin = inputPin.trim();
      if (pin.length == 4) {
        // Data Topup
          if (details.service == "Data Topup") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number, }
            let response = await sendRequest(payload, "/data/topup");
            dispatch({type: 'stop'});
              if (response.code == 200) {                                    
                  storage("remove", "invoice", null, "sessionStorage");
                  let mobileNumberArray = details.number.split(',');
                  let route = "/receipt";
                  if (mobileNumberArray.length > 1) {
                     route = "/transactions";
                  }
                  return storage("save", "receipt", response, "sessionStorage") ? navigate(route) : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return response.code !== 426 ? toaster("error", response.message) : setVerifyModal(true);
              } else {
                return alert(error.whoops);
              }

            // Airtime Topup
          } else if (details.service == "Airtime Topup") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.amount == "" 
            || details.amount == undefined) return toaster("error", error.Oops);
              dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, amount: details.amount,}
              let response = await sendRequest(payload, "/airtime/topup");
              dispatch({type: 'stop'});
              if (response.code == 200) {                
                storage("remove", "invoice", null, "sessionStorage");
                
                let mobileNumberArray = details.number.split(',');
                let route = "/receipt";
                if (mobileNumberArray.length > 1) {
                   route = "/transactions";
                }
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }
                
                // Cable subscription
            } else if (details.service == "Cable Subscription") {
              if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.cardNumber == "" 
              || details.cardNumber == undefined) return toaster("error", error.Oops);
              dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, cardNumber: details.cardNumber }
              let response = await sendRequest(payload, "/cable/subscription");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }

              // Bills Payment
          } else if (details.service == "Bills Payment") {
              if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined 
                || details.meterNumber == "" || details.meterNumber == undefined || details.amount == "" || 
                details.amount == undefined || details.type == "" || details.type == undefined) return toaster("error", error.Oops);
                dispatch({type: 'start'});
              let payload = { id: details.id, pin: pin, number: details.number, type: details.type, meterNumber: details.meterNumber, amount: details.amount, }            
              let response = await sendRequest(payload, "/bills/payment");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              }

              // Smile Bundle
          } else if (details.service == "Smile Bundle") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined || details.smileNumber == "" || details.smileNumber == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number, smileNumber: details.smileNumber, }            
            let response = await sendRequest(payload, "/smile/topup");
            dispatch({type: 'stop'});
            if (response.code == 200) {
              storage("remove", "invoice", null, "sessionStorage");
              return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
            } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.message);
            } else {
              return alert(error.whoops);
            } 
            

          } else if (details.service == "Result Checker") {
            if (details.id == "" || details.id == undefined || details.quantity == "" || details.quantity == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, quantity: details.quantity, }            
            let response = await sendRequest(payload, "/exam/pin");
            dispatch({type: 'stop'});
            if (response.code == 200) {
              storage("remove", "invoice", null, "sessionStorage");
              return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
            } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                dispatch({type: 'stop'});
                return toaster("error", response.message);
            } else {
              return alert(error.whoops);
            } 


          } else if (details.service == "Recharge Pin") {
            if (details.id == "" || details.id == undefined || details.quantity == "" || details.quantity == undefined
            || details.range == "" || details.range == undefined || details.name == "" || details.name == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, range: details.range, quantity: details.quantity, name: details.name, }            
            let response = await sendRequest(payload, "/recharge-pin/purchase");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 

          } else if (details.service == "Airtime To Cash") {
            if (details.id == "" || details.id == undefined || details.mode == "" || details.mode == undefined
            || details.amount == "" || details.amount == undefined || details.value == "" || details.value == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, mode: details.mode, amount: details.amount, value: details.value, }            
            let response = await sendRequest(payload, "/airtime-to-cash/convert");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 
          } else if (details.service == "Spectranet") {
            if (details.id == "" || details.id == undefined || details.number == "" || details.number == undefined
            || details.amount == "" || details.amount == undefined || details.plan == "" || details.plan == undefined) return toaster("error", error.Oops);
            dispatch({type: 'start'});
            let payload = { id: details.id, pin: pin, number: details.number}            
            let response = await sendRequest(payload, "/spectranet/pin");
              dispatch({type: 'stop'});
              if (response.code == 200) {
                storage("remove", "invoice", null, "sessionStorage");
                return storage("save", "receipt", response, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);              
              } else if(response.code !== 200 && response.status == "failed" && response.message !== "") {
                  dispatch({type: 'stop'});
                  return toaster("error", response.message);
              } else {
                return alert(error.whoops);
              } 
          }


      } else {
        return toaster("error", 'Please input your transaction pin.');
      }
    }

  }


    useEffect(() => {
      scrollToTop();
      invoice();
    }, [])


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

{/* <Top title='Review Order'/> */}


<div className='go_back_style' style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px 0px 0px', padding: '0px 15px' }}>
        <h3 className=''><div className='go_back' onClick={() => navigate(-1)}> <i className='fas fa-arrow-left'></i> Go Back </div></h3>
        <div className='vertical-line'></div>
        <div className='text-right content-header-text'>
          <div className='font_lobster'>
            Review Transaction 
            {/* <img style={{ borderRadius: '50%' }} width={15} src='/images/networks.png' /> */}
          </div>
        </div>
      </div>

<div>


</div>

{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content mt-0" style={{ padding: '0px 10px 0px 10px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12">

          {/* <div className='text-center mb-2' style={{ color: '#475f7b', fontSize: '12.5px' }}>Before you proceed, please double-check the transaction you're about to make.</div> */}
          {/* <div className='text-center mb-1'>Thank you for choosing us 🤝</div> */}
          {/* <div>Please confirm your transaction before proceeding</div> */}

          <div className='mt-1 mb-1' style={{ padding: '0px 0px' }}>
            <div className='box mb-0' style={{ cursor: 'context-menu' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <i className='fab fa-opencart select_list_text'></i> 
                      <span className='select_list_text' style={{ marginLeft: '15px' }}>Service</span> 
                    </span>
                    <span className='select_list_text'> {details.service} </span>
                </div>           
            </div>
        </div>



        <div className='mt-0 mb-1' style={{ padding: '0px 0px' }} hidden={details.service == "Cable Subscription" || details.service == "Bills Payment" 
          || details.service == "Smile Bundle" || details.service == "Result Checker" || details.service == "Spectranet"  ? true : false}>
            <div className='box mb-0' style={{ cursor: 'context-menu' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <i className='fas fa-mobile-alt select_list_text'></i> 
                      <span className='select_list_text' style={{ marginLeft: '15px' }}>Network</span> 
                    </span>
                    <span className='select_list_text'> {details.network} </span>
                </div>           
            </div>
        </div>


        <div className='mt-0 mb-1' style={{ padding: '0px 0px' }} hidden={details.type !== undefined || details.plan !== undefined  ? false : true}>
            <div className='box mb-0' style={{ cursor: 'context-menu' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <i className='far fa-arrow-alt-circle-right select_list_text'></i> 
                      <span className='select_list_text' style={{ marginLeft: '15px' }}>{ details.service == "Data Topup" || details.service == "Cable Subscription" || details.service == "Smile Bundle" || details.service == "Spectranet"  ? "Plan" : "Type" }</span> 
                    </span>
                    <span className='select_list_text'> { details.service == "Data Topup" || details.service == "Cable Subscription" || details.service == "Smile Bundle" || details.service == "Spectranet"  ? details.plan : details.type } </span>
                </div>           
            </div>
        </div>


        <div className='mt-0 mb-1' style={{ padding: '0px 0px' }}>
            <div className='box mb-0' style={{ cursor: 'context-menu' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <i className='fab fa-amazon-pay select_list_text'></i> 
                      <span className='select_list_text' style={{ marginLeft: '15px' }}>Amount</span> 
                    </span>
                    <span className='select_list_text'>₦{ numberArray.length !== 0 ? details.amount * numberArray.length : details.amount }</span>
                </div>           
            </div>
        </div>

          
        <div className='mt-0 mb-1' style={{ padding: '0px 0px' }} hidden={numberArray.length == 0 ? true : false }>
            <div className='box mb-0' style={{ cursor: 'context-menu' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <i className='fas fa-phone select_list_text'></i> 
                      <span className='select_list_text' style={{ marginLeft: '15px' }}>Phone Number</span> 
                    </span>
                    <span className='select_list_text'>{ numberArray.length == 1 ? details.number : numberArray.length + ' Numbers' }</span>
                </div>           
            </div>
        </div>



        <div className='flex mb-2 mt-2' style={{ justifyContent: 'space-around', alignItems: 'center' }}>
        
        <span style={{color: "#1f1f1f", fontSize: '15px'}}>Enter Pin: </span>        
          
          <span><input type={"text"} onChange={(e) => {pinInputHandler(e.target.value)}} autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} inputMode="numeric"          
              placeholder="****" value={inputPin} className="form-control pin-input" id="input_pin" />    
          </span> 



          {/* <span><i onClick={() => {
            var state = !pinIcon;
            setPinIcon(state);
            }} className={pinIcon ? 'fa fa-eye' : 'fas fa-eye-slash'} style={{color: "#cbcbcb", fontSize: "15px" }}></i>
          </span> */}

          <span><img src="/images/backhand-index-pointing-left.png" width={30}></img></span>

        </div>


        <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
        { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loading ? '' : 'Proceed ' }
          { !loading && <i className='fas fa-circle-arrow-right'></i> }
      </button>



          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Invoice