// import React from 'react'
import axios from 'axios';
import { useReducer, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, formatDate, storage, toaster, siteLogo, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer } from 'react-toastify';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';

function Coupon() {

    const navigate = useNavigate();   
    const [code, setCode] = useState('');
    var [attempt, setAttempt] = useState(0);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    
    async function proceed(e) {
        e.preventDefault();        
        if (code == "") return toaster("error", "Please enter coupon code");        
        if (!loading){
            dispatch({type: 'start'});   
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(baseURL+"/fund-wallet/coupon",  {
                code: code,
                attempt: attempt,
          }).then((response) => {
            dispatch({type: 'stop'});
            if (response.data.status == "failed" && response.data.message !== "") {
                 setAttempt(attempt+1);
                return toaster("error", response.data.message);
              } else if (response.data.status == "success") {
                return toaster("success", response.data.message);                              
                  } else {
                    return toaster("error", error.whoops);    
                  }
      
            }).catch((e) => {
                if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                  // console.log(e);
                    return toaster("error", error.loading.excemption);
            }) 
        }       
    }


  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '17px' }}> Fund With Coupon </span> 
                        <span className='' style={{ marginLeft: '5px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                        Enter your coupon code in the field below to get your account credited.
                        </span>                      
                        </div> 
                    </div>
                  <img src={"/images/Coupons.jpg"} width={55} height={55} style={{ borderRadius: '50%' }}/>
                </div> 
                    
          <div className='mt-2 mb-1'>
            <div className="form-group mb-2">
              <input type="text" autoCorrect='off' autoComplete='off' placeholder='Enter coupon' 
              value={code} onChange={(e) => setCode(e.target.value)} maxLength="50" className="form-control input-area" id="number" /> 
            </div>
        </div>


<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
    { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
    { loading ? '' : 'Proceed' } <i className='fas fa-circle-arrow-right'></i>
      </button>

      {/* <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div> */}
          
</div>


              
            </div>
        </div>
        



       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default Coupon