import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Data from './pages/Data';
import Home from './pages/Home';
import Receipt from './pages/Receipt';
import ProtectedRoute from './functions/ProtectedRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Invoice from './pages/Invoice';
import Transactions from './pages/Transactions';
import Airtime from './pages/Airtime';
import Cable from './pages/Cable';
import Electricity from './pages/Electricity';
import RechargePin from './pages/RechargePin';
import Exam from './pages/Exam';
import Spectranet from './pages/Spectranet';
import Smile from './pages/Smile';
import BulkSMS from './pages/BulkSMS';
import FundWallet from './pages/FundWallet/Fund-Wallet';
import Atm from './pages/FundWallet/Atm';
import Coupon from './pages/FundWallet/Coupon';
import VirtualAccount from './pages/FundWallet/Virtual-Account';
import ManualFunding from './pages/FundWallet/ManualFunding';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import ResetPIN from './pages/ResetPIN';
import NotFound from './pages/NotFound';
import Referral from './pages/Referral';
import Verification from './pages/KYC/Verification';
import DynamicAccount from './pages/FundWallet/Dynamic-Account';
import Info from './pages/KYC/Info';

function App() {
  return (
    <div>
        <Router>
          <Routes>
            <Route exact path='/login' element={<Login /> }/>
            <Route exact path='/register' element={<Register /> }/>
            <Route exact path='/forgot-password' element={<ForgotPassword /> }/>
            <Route exact path='/reset-password' element={<ResetPassword /> }/>
            <Route exact path='/change-password' element={<ChangePassword /> }/>
            <Route exact path='/reset-pin' element={<ResetPIN /> }/>
            <Route exact path='/' element={<ProtectedRoute Component={Home} />} />
            <Route exact path='/data' element={<ProtectedRoute Component={Data} />} />
            <Route exact path='/receipt' element={<ProtectedRoute Component={Receipt} />}/>
            <Route exact path='/invoice' element={<ProtectedRoute Component={Invoice} />}/>
            <Route exact path='/transactions' element={<ProtectedRoute Component={Transactions} />}/>
            <Route exact path='/airtime' element={<ProtectedRoute Component={Airtime} />} />
            <Route exact path='/cable' element={<ProtectedRoute Component={Cable} />} />
            <Route exact path='/electricity' element={<ProtectedRoute Component={Electricity} />} />
            <Route exact path='/recharge-pin' element={<ProtectedRoute Component={RechargePin} />} />
            <Route exact path='/exam' element={<ProtectedRoute Component={Exam} />} />  
            <Route exact path='/spectranet' element={<ProtectedRoute Component={Spectranet} />} />
            <Route exact path='/smile' element={<ProtectedRoute Component={Smile} />} /> 
            <Route exact path='/bulk-sms' element={<ProtectedRoute Component={BulkSMS} />} />  
            <Route exact path='/profile' element={<ProtectedRoute Component={Profile} />} />
            <Route exact path='/referral/log' element={<ProtectedRoute Component={Referral} />} />

              {/* KYC Route */}
            <Route exact path='/kyc/verify' element={<ProtectedRoute Component={Verification} />} />
            <Route exact path='/kyc/verification-info' element={<ProtectedRoute Component={Info} />} />

              {/* Fund Wallet */}
            <Route exact path='/fund-wallet' element={<ProtectedRoute Component={FundWallet} />} />
            <Route exact path='/fund-wallet/atm' element={<ProtectedRoute Component={Atm} />} />
            <Route exact path='/fund-wallet/coupon' element={<ProtectedRoute Component={Coupon} />} />
            <Route exact path='/fund-wallet/virtual-account' element={<ProtectedRoute Component={VirtualAccount} />} />
            <Route exact path='/fund-wallet/manual-funding' element={<ProtectedRoute Component={ManualFunding} />} />                                
            <Route exact path='/fund-wallet/dynamic-account' element={<ProtectedRoute Component={DynamicAccount} />} />

              {/* 404 Not Found */}
            <Route path="*" element={<NotFound/>} />

          </Routes>
        </Router>
        
    </div>
  );
}

export default App;
