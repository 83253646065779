// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
function Atm() {

  const navigate = useNavigate();   

  
  const [amount, setAmount] = useState('');
  const [loading, dispatch] = useReducer(loadingReducer, false);  
  const [gateway, setGateway] = useState('');
  const [paystack, setPaystack]   = useState([]);
  const [monnify, setMonnify]   = useState([]);
  const [loadingRequest, dispatchRequest]   = useReducer(loadingReducer, false); 

  async function proceed(e) {
      e.preventDefault();        
      if (amount == "") return toaster("error", "Please input an amount");
      if (gateway == "") return toaster("error", "Please select a payment gateway");
          var endpoint = null;
          switch(gateway) {
              case "paystack": endpoint = baseURL+"/fund-wallet/paystack";
              break;
              case "monnify": endpoint = baseURL+"/fund-wallet/monnify";
              break;
              default: endpoint = null;
          }
      
      if (!loadingRequest){
          dispatchRequest({type: 'start'});   
          axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
          await axios.post(endpoint,  {
              amount: amount,
              gateway: gateway,
        }).then((response) => {
          dispatchRequest({type: 'stop'});
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                    window.location.href = response.data.checkout;
                } else {
                  return toaster("error", "Wallet topup failed please try again");    
                }

    
          }).catch((e)=>{
              if (e.response !== undefined) {
                  if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                      storage("remove", "data", null, "localStorage");
                      return navigate("/login");
                  }
                }
                  return toaster("error", error.loading.excemption);
          }) 
      }       
  }




  async function load() {
      dispatch({type: 'start'});              
        await axios.post(baseURL + "/fund-wallet/atm-card",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
        }).then((response) => {
            // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {          
              setPaystack(response.data.paystack);
              setMonnify(response.data.monnify);
              dispatch({type: 'stop'});
        } else {
          return toaster("error", error.loading.excemption);    
        }

        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            return toaster("error", error.loading.excemption);
        })
    }

    const amountHandler = (e) => {
      var amount = e.target.value;
      if (amount < 999999) {
        setAmount(amount);
      }
    }


    function totalAmount(charge) {      
      var charge = charge/100 * amount;
      // console.log(amount);
      return  parseInt(amount) + parseInt(charge);
    }

    useEffect(() => { load(); return () => axios.CancelToken.source(); }, []);




  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '17px' }}> Fund Wallet </span> 
                        <span className='' style={{ marginLeft: '5px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                        <i>Enter amount you wish to fund your wallet and select any of the payment gateway.</i>
                        </span>                      
                        </div>
                    </div>
                    <img src={"/images/credit-card_.png"} width={55} height={55} className='fw_img_shadow' style={{ borderRadius: '50%' }}/> 
                </div> 
                    
      <div className='mt-2 mb-0'>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={amount} onChange={amountHandler} min="0" max="999999"
            placeholder='Amount' className="form-control input-area" id="number" /> 
        </div>
    </div>

    <div className="form-group styled-select mb-2">
    <select id="type" className="form-control select-input" onChange={(e) => setGateway(e.target.value)}>
      <option value=""> Select payment gateway  </option>
      { monnify.status == "available" ? <option value="monnify"> Monnify  </option> : '' }  
      { paystack.status == "available" ? <option value="paystack"> Paystack  </option> : '' }
         
    </select>
    </div>

<div hidden={gateway == "" ? true : false}>
  <div className='flex'> 
    <div className='box p-2 pr-3 pl-3' style={{ justifyContent: 'center' }}> 
      
        { gateway == "monnify" ? <img src="/images/monnify.png"/> : null }
        { gateway == "paystack" ? <img src="/images/1200px-Paystack_Logo.png"/> : null }
        
    </div>

    <div style={{ width: '5%' }}></div>

    <div className='box p-2 text-center'> 
      <div style={{ fontWeight: '600' }}>
        {gateway.charAt(0).toUpperCase() + gateway.substring(1, gateway.length)}
      </div> 
      
        <div className='flex justify-content-sb'> <div> Charge </div> 
          <div style={{ fontWeight: '600', color: 'red' }}> 
          +₦{gateway == 'paystack' ? paystack.charge/100 * amount : null} 
          {gateway == 'monnify' ? monnify.charge/100 * amount : null} 
          </div> 
        </div>

        <div className='flex justify-content-sb'> <div> Total </div> 
        <div style={{ fontWeight: '600', color: 'green' }}> 
        ₦{gateway == 'paystack' ? totalAmount(paystack.charge) : null} 
          {gateway == 'monnify' ? totalAmount(monnify.charge) : null}
        </div> 
        </div>        
      </div>
    </div> 
  </div>

<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
    { loadingRequest ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loadingRequest ? '' : 'Proceed' } <i className='fas fa-circle-arrow-right'></i>
      </button>

      <div className='text-center mt-2 mb-3'>
        <img src='/images/mastercard-visa-verve.png' width={'170'}/>
      </div>
          
</div>


              
            </div>
        </div>
        



       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default Atm