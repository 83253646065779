// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import error from '../../json/error.json';
import { ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { loadingReducer } from '../../reducers/loading-reducer';
import CopyToClipboard from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
function DynamicAccount() {

  const navigate = useNavigate();   
  
  const [amount, setAmount] = useState('');
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [account, setAccounts] = useState([]);
  const [display, setDisplay] = useState(false);

  async function proceed(e) {
      e.preventDefault(); 
      setDisplay(false);       
      if (amount == "") return toaster("error", "Please input an amount");

      if (!loading){
          dispatch({type: 'start'});   
          axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
          await axios.post(baseURL + "/fund-wallet/dynamic-virtual-account",  {
              amount: amount,
        }).then((response) => {
          dispatch({type: 'stop'});
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
                setAccounts(response.data.account);
                setDisplay(true);
              dispatch({type: 'stop'});
                return toaster("success", "Transfer to the displayed account below.");

            } else {
              return toaster("error", error.whoops);   
            }

    
          }).catch((e)=>{
              dispatch({type: 'stop'});
              if (e.response !== undefined) {
                  if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                      storage("remove", "data", null, "localStorage");
                      return navigate("/login");
                  }
                }
                  return toaster("error", error.loading.excemption);
          }) 
      }       
  }


  const amountHandler = (e) => {
    var amount = e.target.value;
    if (amount < 999999) {
      setAmount(amount);
    }
  }




  
const onCompleteHandler = () => setDisplay(false);
  // Random component
const Completionist = () => <span><b>expired</b></span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      // <span>
      //   {hours}:{minutes}:{seconds}
      // </span>

      <span>
        <b> {minutes}:{seconds}mins</b>
      </span>
    );
  }
};





 function AccountCard(props) {

return (<div>

    <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
        <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
        
        <div className='text-center mt-1 mb-3'><img src="/images/monnify.png" width={150}/></div>
        
            <div className='container' style={{ background: "#dbf5f6", border: "none", padding: "10px", borderRadius: "8px" }}>
                <span style={{ color: "#073a47", fontSize: "12px" }}> Amount to pay</span>
                <div style={{ color: "#063a4f", fontSize: "24px", fontWeight: "bold" }}> ₦{amount} </div>
                <hr style={{ margin: "8px 5px", color: "#b5e0e5" }}/>
                <span style={{ color: "#71979a", fontSize: "11px" }}> Amount </span> <span style={{ color: "#71979a", fontWeight: "700", fontSize: "11px" }}> ₦{amount} </span> 
                <span style={{ color: "#71979a", fontSize: "11px", borderLeft: "1px solid #b5e0e5", paddingLeft: "4px" }}> Fee: </span> 
                <span style={{ color: "#71979a", fontWeight: "700", fontSize: "11px" }}> {props.chargeType == "VAT" ? "₦" : ""}{props.charge}{props.chargeType == "FLAT" ? "%" : ""} </span>

            </div>

            <p className='text-center mt-2'> Transfer to account details below </p>

            <div className='container text-center' style={{ marginBottom: "25px", border: "1px dashed #2a7ab0", background: "rgba(180,203,213,.1)",  borderRadius: "8px", padding: "12px 0px", boxShadow: "rgb(41 122 175 / 79%) 5px 5px, rgb(41 122 176 / 58%) 10px 10px, rgb(41 123 177 / 37%) 15px 15px, rgb(41 121 173 / 21%) 20px 20px, rgb(42 125 181 / 5%) 25px 25px" }}>
                <div className='mb-1' style={{ color: "rgb(0 150 211)", fontWeight: "800" }}> { props.bank } </div>
                <div className='mb-2' style={{ color: "red", letterSpacing: "2px", fontWeight: "800", fontSize: "16px"}}> 
                { props.number }         
                <CopyToClipboard text={props.number}
                onCopy={() => toaster("success", "Account number successfully copied to clipboard")}> 
                    <span style={{ marginLeft: "6px",  color: "grey" }}><i className="fas fa-copy"></i></span> 
                </CopyToClipboard>
                </div>

                <div className='mb-3' style={{ fontSize: "11px" }}> Do not save this account no.! </div>
                <div style={{ fontSize: "13px", fontWeight: "700", color: "#b4cbd5" }}> Account Name </div>
                <div style={{ color: "green", fontSize: "15px", fontWeight: "800" }}> {props.name} </div>
                <hr/>
                <div className='mb-1' style={{ color: "#b0b1b1", fontSize: "12px" }}>This one-time account expires in <Countdown date={Date.now() + 2400000} renderer={renderer} onComplete={onCompleteHandler}/></div>
                <div>Telecomplug</div>
            </div>      

          
            </div>
        </div>
    </div>
    );


  }


  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }} hidden={display}>
            <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '19px' }}> Dynamic Funding </span> 
                        <span className='' style={{ marginLeft: '5px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                        <i>Enter the amount you wish to fund and pay into the generated account. 
                            <span style={{ color: "red", fontWeight: "bold" }}> No verification required</span>  </i>
                        </span>                      
                        </div>
                    </div>
                    <img src={"/images/bank-logos.jpg"} width={55} height={55} className='fw_img_shadow' style={{ borderRadius: '50%' }}/> 
                </div> 
                    


      <div className='mt-2 mb-0'>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={amount} onChange={amountHandler} min="0" max="999999"
            placeholder='Amount' className="form-control input-area" id="number" /> 
        </div>
    </div>



<div className='mt-0 mb-2' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn' onClick={proceed}>
    { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loading ? '' : 'Proceed' } </button>

      <div className='text-center mt-1 mb-2'>
      <img src="/images/monnify.png"/>
        {/* <img src='/images/mastercard-visa-verve.png' width={'170'}/> */}
      </div>
          
</div>


              
            </div>
        </div>
        




    <div hidden={!display}>
        <AccountCard name={account["name"]} number={account["number"]} bank={account["bankName"]} ussd={account["ussdPayment"]} charge={account["charge"]} chargeType={account["chargeType"]}/>
    </div>


       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default DynamicAccount