import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
// import Carousel from 'nuka-carousel/lib/carousel'
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import Switch from 'react-switch';
import error from '../json/error.json';
import Top from '../components/service/Top';
import Networks from '../components/service/Networks';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import NetworkSkeleton from '../components/Skeleton/NetworkSkeleton';
import { PulseLoader } from 'react-spinners';
import ValidateCard from '../components/service/ValidateCard';
import SetPIN from '../components/modals/SetPIN';

function Data() {
  const navigate = useNavigate();
  var defaultPlanTxt = 'Select plan';
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [pinModal, setPinModal] = useState(false);
  const [network, setNetwork] =  useState('');
  const [wallet, setWallet] = useState('');
  const [toggle, setToggle] =  useState(false);
  const [plans, setPlans] = useState([]);
  const [type, setType] = useState('');
  const [planTypes, setPlanTypes] = useState([]);
  const [dataPlans, setDataPlans] = useState([]);
  const [validateBulkNumber, setValidateBulkNumber] = useState(true);
  const [planText, setPlanText] = useState(defaultPlanTxt)
  const [value, setValue] = useState({
    hideResult: true,
    validNumbers: '',
    InvalidNumbers: '',
    duplicateNumbers: '',
    selectedPlan: [],
    phoneNumber: '',
    validate: {
      number: true,
    },
    prefix: [],
    networks: [],
});

  const fetch_plans = async () => {
    dispatch({type: 'start'});
    await axios.post(baseURL+"/data/plans",  {
  }, {    
    headers:{
        'Authorization':`Bearer ${user().token}`,
        'Content-Type': 'application/json'
    }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          PINHandler(!response.data.pin);
          setPlans(response.data.plans);
              setValue({
                ...value,
                prefix: response.data.prefix,
                networks: response.data.networks,
                });
                setWallet(response.data.balance);
                // setBeneficiaryList(response.data.beneficiaries);
                dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }



//  Plans action sheet
  const ref = useRef();

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    ref.current.close();
  }


  const toggleHandler = (checked) => {
    let newValidateBulkNumber = !validateBulkNumber;
    // console.log(toggle);
      setValue({...value, hideResult: true, phoneNumber: ''});      
      setToggle(checked);
      // setValidateBulkNumber(!modalShow);
 }

    // function network(provider) {
    //   console.log(provider);
    //   // return provider;
    // }

    function phoneNumberHandler(e) {
      setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
    }

    /**************************************************************************/
// Validate Number
  const validateNumber = (phoneNumber) => {
    if (value.validate.number == true) {
      if (phoneNumber.length == 11) {
        let networkPrefix =  value.prefix.filter((e)=> {
              return phoneNumber.startsWith(e.prefix);
          });
          // console.log(networkPrefix);
          if (networkPrefix.length == 1) {
          let network = networkPrefix[0].network;
              // document.getElementById("network").value = network;
              setNetwork(network);
              networkHandler({target: {value: network}});
          }
        }
      }
  }

  /**************************************************************************/

  function reset_plan_type() {
    setPlanTypes([]); 
    setDataPlans([]);
    setType('');
  }



  
function validateTextArea(result) {
  var arr = []; // New array of sanitized numberArray
  var InvalidNumber = []; // Array of invalid number(s) excluded
  var numbersArray = result.split(','); // Turn uploaded string to array
      //   Remove white space
  for (let i = 0; i < numbersArray.length; i++) {
      let number = numbersArray[i].trim()
          if (number !== "" && !isNaN(number)) {
            if (number.length == 11) {
                arr.push(number);
            } else {
                InvalidNumber.push(number);
            }
        }
    }
      const unique = Array.from(new Set(arr)); // Remove duplicate numbers
      var phoneNumber = unique.toString();
       // Duplicate numbers **** Subtract unique from total numbers
      let count = arr.length - unique.length;
      setValue({
        ...value,
        phoneNumber: phoneNumber,
        hideResult: false,
        duplicateNumbers: count,
        validNumbers: unique.length,
        InvalidNumbers: InvalidNumber.length,
      });
}





  const fileHandler = (event) => {
    var file = event.target.files[0];
    if (file.name.includes(".txt") || file.name.includes(".csv")) {
        var reader = new FileReader();
        reader.onload = function(event) {
            var result = event.target.result;
            setValidateBulkNumber(false);
            setValue({...value, phoneNumber: result});
            // Validate textarea input
          // validateTextArea(result);
          // setValidateBulkNumber(true);
        }
              reader.readAsText(file);
    } else {
        return toaster("error", error.validation.invalidCSVTXT);
    }
  
  }
  

  
/**************************************************************************/

const [image, setImage] = useState({
  src: '/images/networks.jpg',
  borderColor: '#122614 #edc704 #17a00b #ed1d23',
  imageWidth: '37px',
  spanBorder: '1px solid',
  rotate: 'rotate',
})


const networkImage = (network) => {
        // console.log(network);
    switch (network) {
      // MTN
    case "MTN": setImage({
        src: "/images/mtn-logo.jpg",
        borderColor: '#f9c905',
        imageWidth: '35px',
        spanBorder: '2px solid',
        rotate: '',
    });
    
    break;
    // GLO
    case "GLO": setImage({
      src: "/images/glo-logo.jpeg",
      borderColor: '#48b83e',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // AIRTEL
    case "AIRTEL": setImage({
      src: "/images/airtel-logo.webp",
      borderColor: '#ed1b24',
      imageWidth: '35px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // 9MOBILE
    case "9MOBILE": setImage({
      src: "/images/9mobile-logo.png",
      borderColor: '#006d52',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

      default: setImage({
        src: '/images/networks.jpg',
        borderColor: '#122614 #edc704 #17a00b #ed1d23',
        imageWidth: '37px',
        spanBorder: '1px solid',
        rotate: 'rotate',
      });

    }

}


/**************************************************************************/


const networkHandler = (network) => {
  // console.log(provider);
  setNetwork(network);
  networkImage(network);
  setPlanText(defaultPlanTxt);  
  let types = [];
  if (network !== "") {
  for (let i = 0; i < plans.length; i++) {
      if (plans[i].network === network) {
        types.push(plans[i].type);
      }
    }

      reset_plan_type();
      setValue({...value, selectedPlan: []});

    const unique = Array.from(new Set(types));
    if (unique.length == 0) {
      toaster("error", error.service.notAvailable);
 
    } else if (unique.length == 1)  {      
      var plan = plans.filter((e) => {
        return e.network == network && e.type == unique[0];
      });
      setDataPlans(plan);
      // console.log(plans);
    } else {
      setPlanTypes(unique);
    } 
          
  } else {
    reset_plan_type();
    setValue({...value, selectedPlan: []});
  }
}


/**************************************************************************/

function typeHandler(e) {
  var type_ = e.target.value;
  setPlanText(defaultPlanTxt);
  setValue({...value, selectedPlan: []});
  if (type_ !== "") {
    var plan = plans.filter((e) => {
        return e.network == network && e.type == type_;
    })
    setDataPlans(plan);
    setType(type_);      
  } else {
    setDataPlans([]);
    setType('');
  }
}

/**************************************************************************/

function planHandler(plan_id) {
  var plan = dataPlans.filter((e) => {
    return e.id == plan_id;
  });
  setValue({...value, selectedPlan: plan});
  setPlanText(plan[0].plan + ' - ₦' + plan[0].amount);
  // console.log(plan[0].plan);
  handleClose();
}


function proceed(e) {
  e.preventDefault();
  if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
  if (network == "") return toaster("error", error.validation.emptyNetwork);
  if (value.selectedPlan.length == null || value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
  if (toggle == false && value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
  if (validateBulkNumber === false) {
    validateTextArea(value.phoneNumber);
    setValidateBulkNumber(true);
    return;
  }
    // console.log(value.phoneNumber);
  let phoneNumberArray = value.phoneNumber.split(',');
  let amount = phoneNumberArray.length * value.selectedPlan[0].amount;

  if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance);

    let object = {
        "service": "Data Topup",
        "id" : value.selectedPlan[0].id,
        "network" : value.selectedPlan[0].network,
        "number": value.phoneNumber,
        "plan": value.selectedPlan[0].plan,
        "amount": value.selectedPlan[0].amount,
    };
  object = JSON.stringify(object);
  if(storage("save", "invoice", object, 'sessionStorage')) {
      navigate("/invoice");
  } else {
      return alert(error.whoops);
  }
}


  const PINHandler = (value) => {
    setPinModal(value);
  }


    useEffect(() => {
      fetch_plans()
    }, [])


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Data Topup'/>

<div>


</div>

    <SetPIN visibility={pinModal} state={PINHandler}/>

    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}


{/* ====================================================================== */}


  <div className="col-xl-6">

    { loading ? <NetworkSkeleton/> : null }

      <div className="row">   
      { !loading ?  <Networks provider={networkHandler}/> : null }        
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ toggle || !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>


    <div className='mt-3 mb-0' style={{ padding: '0px 0px' }}  hidden={ toggle || loading  }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          onKeyUp={(e) => validateNumber(e.target.value)} value={value.phoneNumber} 
          onChange={phoneNumberHandler} 
            placeholder='Enter phone number' className="form-control input-area" id="number" />
        </div>
    </div>



    {/* File upload */}
    <div className="upload-btn-wrapper custom_card_shadow mt-3" hidden={!toggle} style={{ borderRadius: "10px"  }} >
    <button className="upload_btn shadow-bg-m shadow-s" style={{ lineHeight: "35px" }}>
        <i className="fa fa-upload" aria-hidden="true"></i> Upload .CSV/TXT file
    </button>
      <input type="file" onChange={fileHandler} name="myfile"/>
    </div>



    {/* textarea */}
    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }} hidden={!toggle}>    
					<div className="form-group mb-2">
					  {/* <label>*</label> */}
					  <textarea className="form-control text-area" style={{ height: '105px' }} rows="3" value={value.phoneNumber} onChange={phoneNumberHandler} onKeyUp={() => setValidateBulkNumber(false)} placeholder="Enter phone numbers seperated by comma (,) e.g 080********, 090********,070********,081********"></textarea>
					</div>
    </div>





    <div className="form-group styled-select mb-2" hidden={ planTypes.length !== null && planTypes.length > 0 ? false : true  }>
    <select id="type" onChange={typeHandler} value={type} className="form-control select-input">
      <option value=""> Please select  </option>
      { planTypes.length !== null && planTypes.length !== 0 ? planTypes.map((e, index) => { return <option value={e} key={index}> {e} </option> }) : ''}
    </select>
    </div>


    <div className='mt-0 mb-3' style={{ padding: '0px 0px' }} hidden={ dataPlans.length !== null && dataPlans.length > 0 ? false : true }>
        <div className='box mb-0' onClick={handleOpen} style={{ cursor: 'context-menu', background: '#fff' }}>
            <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                <span> <img src={image.src} width={30} style={{ borderRadius: '50%' }}/> </span>
                <span className='select_list_text'> {planText} </span>
                <span className='select_list_text'> <i className="fa fa-chevron-right"></i> </span>
            </div>           
        </div>
    </div>



<>
    <ActionSheet ref={ref}>
      <div style={{ height: dataPlans.length * 100 + 'px'}} className='actionsheet_'>
        {/* <div className='mr-10'> <h4> Plans!</h4> </div>
        <div> <button className='btn btn-primary' onClick={handleClose}>Close</button> </div> */}
        { dataPlans.length !== 0 ? dataPlans.map((e, index) => { 
            return <div className='mt-0 mb-2' key={index} style={{ padding: '0px 0px' }}>
            <div className='box mb-0' onClick={() => planHandler(e.id)} style={{ cursor: 'context-menu', background: '#fff', padding: '0px 5px', overflow: 'auto' }}>
                <div style={{ height: '65px', padding:'0px 10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}> 
                    <span> <img src={image.src} width={30} style={{ borderRadius: '50%' }}/> </span>
                    <span className='select_list_text'> { e.plan } { value.selectedPlan.length !== 0 && value.selectedPlan[0].id == e.id ? <i className='fas fa-check text-success'></i>  : '' } </span>
                    <span className='select_list_text'> &#8358;{ e.amount } </span>
                </div>           
            </div>
        </div>
        }) : ''}
      </div>
    </ActionSheet>
  </>



<ValidateCard hideResult={value.hideResult} validNumbers={value.validNumbers} InvalidNumbers={value.InvalidNumbers} duplicateNumbers={value.duplicateNumbers}/>


<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>



<div className='toggle_div mb-3' hidden={ loading }>
        <div className='font_nunito' style={{ fontSize: '15px' }}> Bulk phone number <i className="fas fa-hand-point-right"></i>
          {/* <img width={20} src='/images/backhand-index-pointing-right-joypixels.gif'></img>  */}
        </div>        
        <Switch onChange={toggleHandler} checked={toggle} uncheckedIcon={false} checkedIcon={false} />           
    </div>

{/* Loader */}
    <div className='toggle_div mb-3'  hidden={ !loading }>
        <span className='skeleton_shadow' style={{ width: '50%' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
        <span className='skeleton_shadow' style={{ width: '55px' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
      </div>              
{/* Loading ends here */}

    <button className='btn btn-primary mb-3 tp_btn' hidden={ loading } onClick={proceed}>
    { validateBulkNumber === true ? "Proceed" : "Validate" } <i className='fas fa-circle-arrow-right'></i>
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>
          
</div>




{/* 
    <div className='content-header go_back_style' style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', padding: '5px 15px 0px' }}>
        <div className='font_lobster'> Wallet Balance </div>        
          <div className='font_lobster'>
            props.title 
            
        </div>
      </div> */}

      {/* <div class="chip mb-2" style={{ width: '100%', boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px', background: 'white' }}>
        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Person" width="50" height="50"/>
        <div className='flex justify-content-sb font_lobster'><span> Wallet Balance </span> <span>100000</span> </div> 
      </div> */}


{/* <div hidden={ loading }>
    <div className='font_lobster mb-1'> Data Balance Code </div>
        <div className='flex justify-content-sb mb-2'>
          <div className='p-2 mr-1 data_balance_card text-center' style={{ width: '100%' }}>
            <div>MTN</div><div>*461*4#</div>
          </div>
          <div className='p-2 mr-1 data_balance_card text-center' style={{ width: '100%' }}>
            <div>GLO</div><div>*127*0#</div>
          </div>
          <div className='p-2 mr-1 data_balance_card text-center' style={{ width: '100%' }}>
            <div>AIRTEL</div><div>*140#</div>
          </div>
          <div className='p-2 data_balance_card text-center' style={{ width: '100%' }}>
            <div>9MOBILE</div><div>*228#</div>
          </div>
        </div>        
    </div> */}


    {/* <div hidden={ loading }>
      <div className='font_lobster mb-1' > Data Balance Code </div>

        <div className='data_balance_card'>
          <table class="table table-bordered">        
            <tbody>
              <tr>
                <td>MTN</td>
                <td>GLO</td>
                <td>AIRTEL</td>
                <td>9MOBILE</td>
              </tr>
              <tr>
                <td>*461*4#</td>
                <td>*127*0#</td>
                <td>*140#</td>
                <td>*228#</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div> */}

      {/* <div class="chip">
        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Person" width="96" height="96"/>
        John Doe
      </div> */}



  </div>


  <div className='col-lg-6'> 
    <img style={{ borderRadius: '10px' }} className='custom_card_shadow' hidden={ loading } src={"/images/data-balance-code-telecomplug.jpg"}/> 
  </div>
                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



{/* <footer className="main-footer">
<div className="pull-right d-none d-sm-inline-block">
    <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
      <li className="nav-item">
        <a className="nav-link" href="#">FAQ</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Purchase Now</a>
      </li>
    </ul>
</div>
  &copy; 2020 <a href="../../../index.htm">Multi-Purpose Themes</a>. All Rights Reserved.
</footer> */}


</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Data