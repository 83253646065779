// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, siteLogo, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import CopyToClipboard from 'react-copy-to-clipboard';

function ManualFunding() {

    const navigate = useNavigate();
    async function load() {
        dispatch({type: 'start'});      
        
          await axios.post(baseURL + "/fund-wallet/manual-funding",  {
          }, {    
            headers:{
                'Authorization':`Bearer ${user().token}`,
                'Content-Type': 'application/json'
            }, 
          }).then((response) => {
              // console.log(response.data);
          if (response.data.status == "failed" && response.data.message !== "") {
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {          
                setAccounts(response.data.data);
                setCharge(response.data.charge);
                setChargeType(response.data.chargeType);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }
  
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
          })
      }
  
  
  
      const [accounts, setAccounts] = useState([]);
      const [charge, setCharge] = useState('');
      const [chargeType, setChargeType] = useState('');
      const [loading, dispatch] = useReducer(loadingReducer, false);
      
      useEffect(() => { load(); return () => axios.CancelToken.source(); }, []);

      
    function AccountCard(props) {


      return (
      <div className='box p-2 mb-3' style={{ cursor: 'context-menu', borderRadius: '10px'}} hidden={ loading }>

            <div className='text-center' style={{ marginLeft: '5px' }}> {props.bankName} </div>                                                        
              <div className='flex justify-content-sb'> 
                <img src={siteLogo} className="rounded" width={50} height={40}/>                   
                  <div>
                    <div style={{ marginLeft: '5px', fontSize: '13px', lineHeight: '22px', }}> 
                    Account Number: {props.accountNumber}
                    </div>
                    <div style={{ marginLeft: '5px', fontSize: '12px', lineHeight: '22px' }}> 
                    Name: {user().firstname} {user().lastname}
                    </div> 
                  </div>  
                  
                  <div style={{ marginRight: '5px', fontSize: '12px', lineHeight: '22px' }}> 
                  <div>Minimum: ₦100</div>
                  <CopyToClipboard text={props.accountNumber} onCopy={() => toaster("success", "Account number successfully copied to clipboard")}>
                    <span className='badge border color-black border-green-dark' style={{ cursor: "context-menu" }}> 
                      <i className='fa fa-copy'></i> Copy
                    </span>
                  </CopyToClipboard>  
                  </div>  
                </div>
          
        </div>
      );

    }
    

  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-2 pb-2 pt-2' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                                                                     
                                        
              <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '17px' }}> Manual Funding </span> 
              <span className='' style={{ marginLeft: '5px', fontSize: '13px', lineHeight: '25px' }}> 
              Send money 💰 to the below account number, use your username as narration/reference. 
                Click the notify button to alert us about your payment. Note this mode is not automated. - Thanks for choosing us 🤝.
              </span>                      
                             
            </div>
        </div>
        


        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

      {/* ----------------- */}
      <div className='mt-0 mb-0 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-1 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>
        
    {/* ------------------- */}          

                {accounts.map((e, index) => <AccountCard 
                      key={index}
                      bankName={e.bank_name} 
                      accountNumber={e.account_number} 
                      accountName={e.account_name} 
                      charge={charge} 
                      chargeType={chargeType} 
              />)}

                  
                  
              {/* </div> */}
                
        </div>
       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default ManualFunding