// import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, formatDate, storage, toaster, siteLogo, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'

function FundWallet() {

    const navigate = useNavigate();
   

  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>

{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
        <Link to={'/fund-wallet/virtual-account'}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/bank-transfer-payment-method.jpg"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}> Instant Bank Transfer  </span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            Get funded instantly after making transfer to the account number provided. 
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
            </Link>
        </div>
        
        
        {/* --------------------------------------------------------- */}




        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
        <Link to={'/fund-wallet/dynamic-account'}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/bank-logos.jpg"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}> Dynamic Funding  </span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            Your wallet will be credited automatically after making transfer into our dynamic account. 
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
            </Link>
        </div>
        
        
        {/* --------------------------------------------------------- */}




        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <Link to={'/fund-wallet/atm'}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/credit-card_.png"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/>
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}> ATM Card Funding </span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            Deposit funds into your wallet with your debit card or bank transfer. 
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
            </Link>
        </div>


        {/* --------------------------------------------------------- */}

    <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
        <Link to={'/fund-wallet/manual-funding'}>
            
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/wallet-icon.png"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}> Manual Funding </span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                        Request for your wallet to be funded if you have made payment into our bank. 
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
        </Link>
    </div>


        {/* --------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
        <Link to={'/fund-wallet/coupon'}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/Coupons.jpg"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}> Coupon Code </span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            Fund your wallet with coupon code generated for you via our system.
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
            </Link>
        </div>


        {/* --------------------------------------------------------- */}

        {/* <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px' }}>
                <div className='flex justify-content-sb' style={{ height: '100px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>
                        <img src={"/images/naira.png"} className='fw_img_shadow' width={55} height={55} style={{ borderRadius: '50%' }}/> 
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '10px' }}>Airtime Funding</span> 
                        <span className='' style={{ marginLeft: '10px', opacity: '0.8', fontSize: '12px', lineHeight: '20px' }}> 
                            Fund your wallet with airtime [We accept both airtime transfer and recharge pin].
                        </span>                      
                        </div>
                    </div>

                    <div className='flex' style={{ alignItems: 'center' }}>                                                
                        <i className="fa-solid fa-chevron-right" style={{ marginLeft: '2px', opacity: '0.8', }}></i>
                    </div>
                </div> 
            </div>
        </div> */}


       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default FundWallet