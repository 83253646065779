import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate   } from "react-router-dom";
import axios from 'axios';
import { auth, storage, toaster, baseURL, siteLogo, siteName } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

function Login() {

	const { state } = useLocation();
    const [loginState, setLoginState] = useState({
      username: '',
      password: '',
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const formHandler = async (event) => {
      event.preventDefault();
      if (!loading) {
      if (loginState.username == "")  return toaster("error", "The username field cannot be empty");
      if (loginState.password == "")  return toaster("error", "The password field cannot be empty");
      if (loginState.username.length < 2)  return toaster("error", "Invalid username supplied ");
      if (loginState.password.length < 2)  return toaster("error", "Invalid password supplied ");
      setLoading(true);
      axios.defaults.headers.common = {'Content-Type': 'application/json'}
      await axios.post(baseURL+"/user/login",  loginState).then((response) => {
          if (response.data.code !== 200 && response.data.status == "failed") {
            setLoading(false);
            return toaster("error", response.data.message);
          } else if (response.data.code == 200 && response.data.status == "success") {
            setLoading(false);
              if (storage("save", "data", response.data.user, 'localStorage') == true) return navigate("/");
          } else {
            setLoading(false);
          }
        }).catch((e)=>{
            // console.log(e);
            setLoading(false);
            alert(error.whoops)
            return e;
        })
      }
    }

      useEffect(() => { 
		if (auth() == true){
			return navigate("/")
		} else {
			if (state && state.message !== '' && state.message !== null) {
				return toaster('success', state.message);
			}
		} } , []);

  return (
    <div>
        <ToastContainer/>
        <div className='pt-5 mb-5' style={{ marginTop: '50px' }}>
<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>
        {/* <div className="auth-2-outer row align-items-center h-p100 m-0"> */}

		<div className="auth-card bg-white">
		  <div className="auth-logo font-size-30 pt-4">
			<img src={siteLogo} width={150}/>
			{/* <a href="../index.html" className="text-dark"><b>Telecom</b>plug</a> */}
		  </div>
          
		  <div className="auth-body" style={{ padding: '5px 12px 10px' }}>
			<p className="auth-msg text-black-50"><i>Sign in and experience a faster, easier and more reliable way to buy data, airtime, pay bills etc.</i></p>

			<form method="post" className="form-element" onSubmit={formHandler}>
			  <div className="form-group has-feedback">
				<input type="text" value={loginState.username} onChange={e => setLoginState({...loginState, username: e.target.value})} className="form-control login-input" placeholder="Enter your username"/>
				<span className="fas fa-user form-control-feedback text-dark mt-2"></span>
			  </div>

			  <div className="form-group has-feedback">
				<input type="password" value={loginState.password} onChange={e => setLoginState({...loginState, password: e.target.value})} className="form-control login-input" placeholder="Enter your password"/>
				<span className="fas fa-key form-control-feedback text-dark mt-2"></span>
			  </div>
			  <div className="row">

                
				<div className="col-6">
				 <div className="fog-pwd">
					<Link to="/forgot-password"><i className="ion ion-locked"></i> Forgot password?</Link><br/>
				  </div>
				</div>
                
                

				<div className="col-12 text-center mt-3">
				<button className='btn btn-primary mb-4 tp_btn'>
				{ loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 				{ loading ? '' : 'Login ' }
				{ loading ? '' : <i className='fas fa-circle-arrow-right'></i> }
      			</button>
				  {/* <button type="submit" className="btn btn-rounded my-20 btn-success">SIGN IN</button> */}
				</div>
                
			  </div>
			</form>


			<div className="margin-top-30 text-center text-dark">
				<p>Don't have an account? <Link to="/register" className="text-info m-l-5">Sign Up</Link></p>
			</div>
			
			<div className='mb-3' style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
				<img src='/images/google_play_btn.png' width={150} height={65}/>
				<img src='/images/app-store-logo-transparent.png' width={150} height={45}/>
			</div>

		  </div>
		</div>
	
	</div> </div> </div> 
    </div>


	<FloatingWhatsApp
		phoneNumber={"2349014463362"}
		accountName={siteName}
		statusMessage="Customer support"
		avatar = "/images/Telecomplug-logo-transaparent.png"
		chatMessage="Hello, What can I do to assist you?"
		allowClickAway
		notification
		notificationDelay={60000} // 1 minute
		notificationSound
	/> 

    </div>
  )
}

export default Login