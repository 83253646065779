import React, { useRef } from 'react'
import Header from '../components/Header'
import Nav from '../components/Nav'
import { useEffect, useReducer, useState } from 'react';
import { baseURL, scrollToTop, toaster, user, siteName, storage, date} from '../functions/O3developer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import CopyToClipboard from 'react-copy-to-clipboard';

function Profile() {

    const navigate = useNavigate();

    const [hook, setHook] = useState('');
    const [value, setValue]  = useState('');
    const [hookLoading, dispatchHookLoading] = useReducer(loadingReducer, false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
  
    const fetchProfile = async () => {
      dispatch({type: 'start'});
        await axios.post(baseURL+"/account/profile",  {
      }, {    
        headers:{
            'Authorization':`Bearer ${user().token}`,
            'Content-Type': 'application/json'
        }, 
    }).then((response) => {
        if (response.data.status == "failed" && response.data.message !== "") {
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {
            // console.log(response.data);
              setHook(response.data.data.url);
                setValue(response.data.data);                
                dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
  
        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            
          return toaster("error", error.loading.excemption);
            // return e;
        })
    }
  
  
    function saveHook(e) {
      e.preventDefault();  
      if (hook !== "" && hook.length > 5) {        
      dispatchHookLoading({type: 'start'});
      axios.post(baseURL+"/webhook/save",  {
        url: hook,
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
    }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
            dispatchHookLoading({type: 'stop'});
            return toaster("error", response.data.message);
        } else if (response.data.status == "success") { 
            dispatchHookLoading({type: 'stop'});
            return toaster("success", response.data.message);                      
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  
    } else {
      return toaster("error", error.validation.requiredInput);
    }
  
    }
  
    useEffect(() => {
        scrollToTop();
        fetchProfile();
        return () => axios.CancelToken.source();
      },[]);
      


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">  

    {/* <!-- Main content --> */}
        <section className="content mt-4" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
{/* ====================================================================== */}


  <div className="col-xl-12 col-12">


<div className='box mt-0 mb-2 select_list_text' style={{ color: '#475f7b', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', fontSize: '14px', borderRadius: '15px'  }}>

<div className="content-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2px 0' }}>
  <Link to='/'><div className='go_back go_back_style'> <i className='fas fa-arrow-left'></i> Go Home </div></Link>
  <div style={{ background: '#205077e3', borderRadius: '5px' }}> <img src='images/user-icon.png' width={40}/> </div>
</div>

  <h2 className='text-center mt-3 mb-0' style={{ fontSize: '20px' }}> Account Profile </h2>
  <div className='text-center'>    
    <div className='' style={{ lineHeight: '23px', padding: '2px 5px', textAlign: 'center' }}>
    {user().firstname} {user().lastname}
      </div>
      

  </div>
  


  <div className='flex justify-content-sb border_bottom receipt_content' style={{ marginTop: '-5px' }}>              
      <span>Phone Number</span>
      <span>{user().phone_number}</span>      
  </div>


  <div className='flex justify-content-sb receipt_content'>              
      <span>Email</span>
      <span>{user().email}</span>  
  </div>





  <table className='table table-striped table-bordered mb-3' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"> Category </span></td>
          <td style={{ fontSize: '12px' }}> {value.category} </td>
        </tr>

        <tr>
            <td style={{ fontSize: '12px' }}> Reg Date </td>
            <td style={{ fontSize: '12px' }}> { date(value.regDate) }  </td>
        </tr> 


        <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"> Status </span></td>
          <td style={{ fontSize: '12px' }}> {value.status} </td>
        </tr>

      
      </tbody>
    </table>


    <div className='pl-2 pr-2 text-center' style={{ wordBreak: 'break-word', fontSize: '12px' }}>
        API KEY <br/> {user().apikey}
        
        {/* <CopyToClipboard text={user().apikey} onCopy={() => toaster("success", "API key successfully copied to clipboard")}>            
            <span className="ms-2 badge" style={{cursor: "context-menu", border: "1px solid #ff5722", color: "#ff5722", }}> Copy API Key</span>
        </CopyToClipboard> */}

    </div>


      <div className="row mb-0 mt-3 text-center">
        <div className="col-6">
        <Link to="/change-password" className="btn btn-primary mb-3 border-0" style={{ color: "white", backgroundColor: "red", fontSize: '14px' }}>Reset Password </Link>
        </div>
        <div className="col-6">
        <Link to="/kyc/verify" className="btn btn-primary mb-3 border-0" style={{ color: "white", backgroundColor: "green", fontSize: '14px' }}>Verify Account</Link>
        </div>
      </div>
   


  </div>

  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Profile