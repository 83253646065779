import axios from 'axios';
import { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseURL, user, date, storage, toaster, skeletonBaseColor } from '../functions/O3developer';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { PulseLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import NoResultFound from './NoResultFound';
import Header from '../components/Header';
import Nav from '../components/Nav';

function Referral() {

    const navigate = useNavigate();
    const [referral, setReferrals] = useState([]);  
    const [pagination, setPagination] = useState({
      next_page_url: '',
      prev_page_url: '',
    });
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [commission, setCommission] = useState('');
    const [count, setCount] = useState('');
    const [refLoading, dispatchRefLoading] = useReducer(loadingReducer, false);
  
    async function load(URL) {
      // console.log(typeof(URL));
    if (URL !== null) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
        dispatch({type: 'start'});
      await axios.post(URL,  {
      }).then((response) => {
        dispatch({type: 'stop'});
          // console.log(response.data.result.data);
          setReferrals(response.data.result.data);
          setCommission(response.data.commission);
          setCount(response.data.count);
          setPagination({...pagination, 
            next_page_url: response.data.result.next_page_url,
            prev_page_url: response.data.result.prev_page_url,
          });
        if (response.data.code == 500) {
            return toaster("error", response.data.message);
        } else if (response.data.code == 200) {
            // console.log(response.data);
        }
  
        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            
          return toaster("error", error.loading.excemption);
        })
      }
    }
  
  
  
  function withdraw(e) {
      e.preventDefault();          
      dispatchRefLoading({type: 'start'});
      axios.post(baseURL+"/commission/withdraw",  {
        type: 'withdrawal',
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
    }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
            dispatchRefLoading({type: 'stop'});
            return toaster("error", response.data.message);
        } else if (response.data.status == "success") { 
            dispatchRefLoading({type: 'stop'});
            setCommission(0);
            return toaster("success", response.data.message);                      
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  
    }
  
  
  
    useEffect(() => { 
      load(baseURL+"/referral/log")
      }, 
    []);
  
  
    function Item(props) {        
  
        return (
          <>
            <div className='mt-0 mb-2' style={{ padding: '0px 0px' }}>
              <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu' }}>
                  <div className='flex justify-content-sb' style={{ height: '55px', padding:'0px 10px', alignItems: 'center'}}>                                                         
                      <div className='flex' style={{ alignItems: 'center' }}>
                          <img src='/images/user-icon.png' width={35} height={35} style={{ borderRadius: '50%' }}/> 
                          <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                          <span className='tranx-header-txt' style={{ marginLeft: '5px' }}> {props.data.username} - <span style={{ fontSize: '10px' }}> { date(props.data.dateTime) } </span> </span> 
                          <span className='tranx-desc-txt' style={{ marginLeft: '5px' }}> {props.data.email} </span>                      
                          </div>
                      </div>
                

                  </div> 
  
              </div>
          </div>
  
          </>
        )
      }
    

  return (
    <div>

    <div className="wrapper">

        <Header/>
        <Nav/>

        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
        <div className="container-full">  
        <section className="content mt-4" style={{ padding: '0px 10px 0px 10px' }}>
         
    <ToastContainer/>

        <div className="box mb-3" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
    <div style={{ padding: "10px 20px 10px 20px" }}>

      { loading ? <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> : 
        <div style={{ color: "#393636", marginBottom: '5px' }} hidden={loading}>
       
  <div className='row mb-0'>

  <p className='mb-0 font-13 font-600'>Commission</p>

      <div className='flex mb-2' style={{ alignItems: 'center' }}>
      <input type="text" autoCorrect='off' readOnly autoComplete='off' value={commission} maxLength="100"        
          placeholder="Commission" className="form-control search-input mr-3" id="search_input" /> 

      <a href="#" onClick={(e) => withdraw(e)} className="btn search-btn" style={{ width: '190px' }}> 
      
      { refLoading ? <PulseLoader color={"#795548"} loading={true} size={8} /> : '' }
          { refLoading ? '' : "Withdraw "  }
          { refLoading ? '' : <i className='fas fa-wallet'></i>  }

        </a>
    </div>



    </div>     

    <span style={{ color: 'green', fontSize: '12px' }}><b> Total Referred User: {count} </b></span>

       </div> }
      
    </div>
    </div>









            { referral.length !== 0 ? referral.map((data, index) => {
                return <Item key={index} data={data} />
                }) : '' }

                {referral.length == 0 && !loading ? <NoResultFound/> : null }

                <div className='text-center' hidden={loading}>
                    <a href="#" hidden={pagination.prev_page_url == null ? true : false} onClick={() => load(pagination.prev_page_url) } className="btn pagination-btn mr-1" style={{ background: 'white', color: '#2c3e50' }}> <i className='fas fa-angle-left'></i> Previous </a>
                    <a href="#" hidden={pagination.next_page_url == null ? true : false} onClick={() => load(pagination.next_page_url) } className="btn pagination-btn ml-1"> Next <i className='fas fa-angle-right'></i> </a>
                </div>


            <div hidden={!loading}>
            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>
            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>

            <div className='skeleton_shadow mb-1'>
                <SkeletonTheme height={60}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
            </div>
        </div>

        <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>



                   
                </section>

                </div>
            </div>        
        </div>
    </div>
  )
}

export default Referral