import React, { useState } from 'react'

function Networks (props) {   
    const [selectedNetwork, setSelectedNetwork] = useState('');

  
    function isSelected(provider) {
      return selectedNetwork == provider && selectedNetwork !== '';
    }

    function network(provider) {
      if (provider !== '') {
        props.provider(provider);
        setSelectedNetwork(provider);
      }
    }

    

  return (

    <div className='box custom_card_shadow' style={{ backgroundColor: '#eef2f480', boxShadow: 'box-shadow: 1.171px 9.607px 15px 0px rgb(71 95 123 / 20%)', margin: '0px 20px 0px 20px' }}>
      <div className='row'>
        <div className='col-3 col_padding'>
          <div className={'network-card ' + (isSelected('MTN') ? 'selected-mtn-network' : '')} 
          onClick={() => network('MTN')} style={{ borderBottomLeftRadius: '10px', borderTopLeftRadius: '10px' 
          }}>
            {isSelected('MTN') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/mtn-logo.jpg' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">MTN</a></h5>  */}
            </div>
          </div>
        </div>


        <div className='col-3 col_padding'>
          <div className={'network-card ' + (isSelected('GLO') ? 'selected-glo-network' : '')}
           onClick={() => network('GLO')}>
            {isSelected('GLO') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/glo-logo.jpeg' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">GLO</a></h5> */}
            </div>
          </div>
        </div>


        <div className='col-3 col_padding'>
          <div className={'network-card ' + (isSelected('AIRTEL') ? 'selected-airtel-network' : '')} 
            onClick={() => network('AIRTEL')}>
              {isSelected('AIRTEL') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/airtel-logo.webp' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">AIRTEL</a></h5> */}
            </div>
          </div>
        </div>

        <div className='col-3 col_padding'>
          <div className={'network-card ' + (isSelected('9MOBILE') ? 'selected-9mobile-network' : '')} 
          onClick={() => network('9MOBILE')} style={{ borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }}>
            {isSelected('9MOBILE') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/9mobile-logo.png' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">9MOBILE</a></h5>                   */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Networks
