import React, { useRef, useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
// import Carousel from 'nuka-carousel/lib/carousel'
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor, decimalCheck} from '../functions/O3developer';
import Switch from 'react-switch';
import error from '../json/error.json';
import Top from '../components/service/Top';
import Networks from '../components/service/Networks';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import NetworkSkeleton from '../components/Skeleton/NetworkSkeleton';
import { PulseLoader } from 'react-spinners';
import ValidateCard from '../components/service/ValidateCard';
import SetPin from '../components/modals/SetPIN';

function Airtime() {
  const navigate = useNavigate();
  
  
const [loading, dispatch] = useReducer(loadingReducer, false);
const [pinModal, setPinModal] = useState(false);
  const [network, setNetwork] =  useState('');
  const [wallet, setWallet] = useState('');
  const [type, setType] = useState('');
  const [airtimeTypes, setAirtimeTypes] = useState([]);
  const [networkAirtime, setNetworkAirtime] = useState([]);
  const [toggle, setToggle] =  useState(false);
  const [amountCharge, setAmountCharge] = useState('');
  const [showDiscount, setShowDiscount] = useState(false);
  const [validateBulkNumber, setValidateBulkNumber] = useState(true);
  // const [beneficiaryToggle, setBeneficiaryToggle] = useState(false);
  // const [modalShow, setModalShow] = useState(false);
  // const [beneficiaryList, setBeneficiaryList] = useState([]);
  // const [BenLoading, dispatchBenLoading] = useReducer(loadingReducer, false);
  const [value, setValue] = useState({
        // textarea: '',
        hideResult: true,
        validNumbers: '',
        InvalidNumbers: '',
        duplicateNumbers: '',
        airtime: [],
        amount: '',
        phoneNumber: '',
        validate: {
          number: true,
        },
        prefix: [],
        networks: [],
  });


const fetch_airtime = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/airtime",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
          // console.log(response.data);
      if (response.data.status == "failed" && response.data.message !== "") {          
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          PINHandler(!response.data.pin);
              setValue({
                ...value,
                airtime: response.data.airtime,
                prefix: response.data.prefix,
                networks: response.data.networks,
              });
                setWallet(response.data.balance);
                // setBeneficiaryList(response.data.beneficiaries);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }
      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }


  const toggleHandler = (checked) => {
    let newValidateBulkNumber = !validateBulkNumber;
    // console.log(toggle);
      setValue({...value, hideResult: true, phoneNumber: ''});      
      setToggle(checked);
      // setValidateBulkNumber(!modalShow);
 }

    // function network(provider) {
    //   console.log(provider);
    //   // return provider;
    // }

    function phoneNumberHandler(e) {
      setValue({...value, phoneNumber: e.target.value.replace(/[^0-9 \,]/, ''), hideResult: true}); 
    }

    /**************************************************************************/
// Validate Number
  const validateNumber = (phoneNumber) => {
    if (value.validate.number == true) {
      if (phoneNumber.length == 11) {
        let networkPrefix =  value.prefix.filter((e)=> {
              return phoneNumber.startsWith(e.prefix);
          });
          // console.log(networkPrefix);
          if (networkPrefix.length == 1) {
          let network = networkPrefix[0].network;
              // document.getElementById("network").value = network;
              setNetwork(network);
              networkHandler({target: {value: network}});
          }
        }
      }
  }

  /**************************************************************************/


  
function validateTextArea(result) {
  var arr = []; // New array of sanitized numberArray
  var InvalidNumber = []; // Array of invalid number(s) excluded
  var numbersArray = result.split(','); // Turn uploaded string to array
      //   Remove white space
  for (let i = 0; i < numbersArray.length; i++) {
      let number = numbersArray[i].trim()
          if (number !== "" && !isNaN(number)) {
            if (number.length == 11) {
                arr.push(number);
            } else {
                InvalidNumber.push(number);
            }
        }
    }
      const unique = Array.from(new Set(arr)); // Remove duplicate numbers
      var phoneNumber = unique.toString();
       // Duplicate numbers **** Subtract unique from total numbers
      let count = arr.length - unique.length;
      setValue({
        ...value,
        phoneNumber: phoneNumber,
        hideResult: false,
        duplicateNumbers: count,
        validNumbers: unique.length,
        InvalidNumbers: InvalidNumber.length,
      });
}





  const fileHandler = (event) => {
    var file = event.target.files[0];
    if (file.name.includes(".txt") || file.name.includes(".csv")) {
        var reader = new FileReader();
        reader.onload = function(event) {
            var result = event.target.result;
            setValidateBulkNumber(false);
            setValue({...value, phoneNumber: result});
            // Validate textarea input
          // validateTextArea(result);
          // setValidateBulkNumber(true);
        }
              reader.readAsText(file);
    } else {
        return toaster("error", error.validation.invalidCSVTXT);
    }
  
  }
  

  
/**************************************************************************/

const [image, setImage] = useState({
  src: '/images/networks.jpg',
  borderColor: '#122614 #edc704 #17a00b #ed1d23',
  imageWidth: '37px',
  spanBorder: '1px solid',
  rotate: 'rotate',
})


const networkImage = (network) => {
        // console.log(network);
    switch (network) {
      // MTN
    case "MTN": setImage({
        src: "/images/mtn-logo.jpg",
        borderColor: '#f9c905',
        imageWidth: '35px',
        spanBorder: '2px solid',
        rotate: '',
    });
    
    break;
    // GLO
    case "GLO": setImage({
      src: "/images/glo-logo.jpeg",
      borderColor: '#48b83e',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // AIRTEL
    case "AIRTEL": setImage({
      src: "/images/airtel-logo.webp",
      borderColor: '#ed1b24',
      imageWidth: '35px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // 9MOBILE
    case "9MOBILE": setImage({
      src: "/images/9mobile-logo.png",
      borderColor: '#006d52',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

      default: setImage({
        src: '/images/networks.jpg',
        borderColor: '#122614 #edc704 #17a00b #ed1d23',
        imageWidth: '37px',
        spanBorder: '1px solid',
        rotate: 'rotate',
      });

    }

}


/**************************************************************************/


const networkHandler = (network) => {
    setNetwork(network);
    networkImage(network);
    setAirtimeTypes([]);
    setNetworkAirtime([]);
    setType('');
    setShowDiscount(false);
    setValue({...value, amount: ''});
  var airtime = value.airtime;
  let types = [];

  if (network !== "") {
    for (let i = 0; i < airtime.length; i++) {
      if (airtime[i].network === network) {
        types.push(airtime[i].type);
      }
    }
    document.getElementById("type").selectedIndex = 0;
      const unique = Array.from(new Set(types));
    //  console.log(unique);
      if (unique.length == 0) {
        toaster("error", error.service.notAvailable);
   
      } else if (unique.length == 1)  {      
        var airtime = value.airtime.filter((e) => {
          return e.network == network && e.type == unique[0];
      });
      setType(unique[0]);
      setNetworkAirtime(airtime);
      } else {
        setAirtimeTypes(unique);
      } 
   
  }
}


/**************************************************************************/

function typeHandler(e) {
    var type = e.target.value;
    setShowDiscount(false);
    setValue({...value, amount: ''});
    if (type !== "") {
      var airtime = value.airtime.filter((e) => {
          return e.network == network && e.type == type;
      })
      setType(type);
      setNetworkAirtime(airtime);
      // console.log(airtime);
    } else {
      setType('');
      setNetworkAirtime([]);
      setValue({...value, amount: ''});
    }
  }

/**************************************************************************/

function amountHandler(e) {
    var amount = e.target.value;
    if (amount < 10000001) {
    setValue({...value, amount: amount})
    var discount = networkAirtime[0].discount;
    //
    if (discount !== '' || discount !== 0) {
      let discount_percentage = discount/100;
      let discount_amount = discount_percentage * amount;
      let amount_charge = amount - discount_amount;
      setAmountCharge(Math.round((amount_charge + Number.EPSILON) * 100) / 100);
    } else {
      setAmountCharge(amount);
    }
    //
    if (amount < 50 || amount == '' || networkAirtime[0].discount == '' || networkAirtime[0].discount == 0) {
      setShowDiscount(false);
    } else {
      setShowDiscount(true);
    }
  }  
}


/**************************************************************************/


function proceed(e) {
    e.preventDefault();
    if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
    if (network == "") return toaster("error", error.validation.emptyNetwork);
    if (type == "") return toaster("error", error.validation.emptyType);
    if (networkAirtime.length !== 1) return toaster("error", error.validation.requiredInput);
    if (value.amount == '' || value.amount < 1) return toaster("error", error.validation.emptyAmount);
    if (toggle == true && value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
    if (value.amount < 50) return toaster("error", "The amount entered falls short of the required minimum (₦50).");
    if (decimalCheck(value.amount)) return toaster("error", "Amount not allowed.");
  
    if (validateBulkNumber === false) {
      validateTextArea(value.phoneNumber);
      setValidateBulkNumber(true);
      return;
    }
  
    let phoneNumberArray = value.phoneNumber.split(',');
    let amount = phoneNumberArray.length * amountCharge;
    // console.log(typeof(amount) + ' ' + typeof(amountCharge) + ' ' + typeof(wallet));
    if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance)
  
  
    let object = {
        "service": "Airtime Topup",
        "id" : networkAirtime[0].id,
        "network" : network,
        "number": value.phoneNumber,
        "type": type,
        "amount": value.amount,
        "amount_to_pay": amount,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }
  }

  const PINHandler = (value) => {
    setPinModal(value);
  }

    useEffect(() => {
        fetch_airtime();
        scrollToTop();
    }, [])


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Airtime Topup'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}


{/* ====================================================================== */}

<SetPin visibility={pinModal} state={PINHandler}/>

  <div className="col-xl-12 col-12">

    { loading ? <NetworkSkeleton/> : null }

      <div className="row">   
      { !loading ?  <Networks provider={networkHandler}/> : null }        
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ toggle || !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>


    <div className='mt-3 mb-0' style={{ padding: '0px 0px' }}  hidden={ toggle || loading  }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          onKeyUp={(e) => validateNumber(e.target.value)} value={value.phoneNumber} 
          onChange={phoneNumberHandler} 
            placeholder='Enter phone number' className="form-control input-area" id="number" /> 
        </div>
    </div>



    {/* File upload */}
    <div className="upload-btn-wrapper custom_card_shadow mt-3" hidden={!toggle} style={{ borderRadius: "10px"  }} >
    <button className="upload_btn shadow-bg-m shadow-s" style={{ lineHeight: "35px" }}>
        <i className="fa fa-upload" aria-hidden="true"></i> Upload .CSV/TXT file
    </button>
      <input type="file" onChange={fileHandler} name="myfile"/>
    </div>



    {/* textarea */}
    <div className='mt-1 mb-0' style={{ padding: '0px 0px' }} hidden={!toggle}>    
        <div className="form-group mb-2">
            {/* <label>*</label> */}
            <textarea className="form-control text-area" style={{ height: '105px' }} rows="3" value={value.phoneNumber} onChange={phoneNumberHandler} onKeyUp={() => setValidateBulkNumber(false)} placeholder="Enter phone numbers seperated by comma (,) e.g 080********, 090********,070********,081********"></textarea>
        </div>
    </div>





    <div className="form-group styled-select mb-2" hidden={ airtimeTypes.length > 0 ? false : true  }>
    <select id="type" onChange={typeHandler} value={type} className="form-control select-input">
      <option value=""> Please select  </option>
        { airtimeTypes.length !== 0 ? airtimeTypes.map((e, index) => { return <option value={e} key={index}> {e} </option> }) : ''}
    </select>
    </div>



    <div className='mt-2 mb-0' style={{ padding: '0px 0px' }}  hidden={ networkAirtime.length > 0 ? false : true }>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} value={value.amount} onChange={amountHandler}
            placeholder='Enter amount' className="form-control input-area" id="number" /> 
        </div>
    </div>


    <div className='mb-2' style={{float: "right", color: "red", fontSize: '12px'}} hidden={!showDiscount}>
        <b> You will be charged ₦{amountCharge} instead of ₦{value.amount} 😊 </b>
    </div>

<ValidateCard hideResult={value.hideResult} validNumbers={value.validNumbers} InvalidNumbers={value.InvalidNumbers} duplicateNumbers={value.duplicateNumbers}/>


<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>


<div className='toggle_div mb-3' hidden={ loading }>
        <div className='font_nunito' style={{ fontSize: '15px' }}> Bulk phone number <i className="fas fa-hand-point-right"></i>
          {/* <img width={20} src='/images/backhand-index-pointing-right-joypixels.gif'></img>  */}
        </div>        
        <Switch onChange={toggleHandler} checked={toggle} uncheckedIcon={false} checkedIcon={false} />           
    </div>

{/* Loader */}
    <div className='toggle_div mb-3'  hidden={ !loading }>
        <span className='skeleton_shadow' style={{ width: '50%' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
        <span className='skeleton_shadow' style={{ width: '55px' }}><SkeletonTheme height={30}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme></span>
      </div>              
{/* Loading ends here */}

    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
    { validateBulkNumber === true ? "Proceed" : "Validate" } <i className='fas fa-circle-arrow-right'></i>
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



{/* <footer className="main-footer">
<div className="pull-right d-none d-sm-inline-block">
    <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
      <li className="nav-item">
        <a className="nav-link" href="#">FAQ</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Purchase Now</a>
      </li>
    </ul>
</div>
  &copy; 2020 <a href="../../../index.htm">Multi-Purpose Themes</a>. All Rights Reserved.
</footer> */}


</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Airtime