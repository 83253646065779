import React, { useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import SetPIN from '../components/modals/SetPIN';

function Exam() {
  const navigate = useNavigate();

/**************************************************************************/
const [pinModal, setPinModal] = useState(false);
const [loading, dispatch] = useReducer(loadingReducer, false);
const [wallet, setWallet] = useState('');
  const [value, setValue] = useState({
        img: '/dashboard/images/graduation-cap-congratulation.png',
        imgWidth: '35px',
        exams: [],
        exam: '',
        selectedExam: [],
        quantity: '',
        amount: ''
  });



  const fetch_exams = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/exam",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
          PINHandler(!response.data.pin);
              setValue({
                ...value,
                exams: response.data.exams,
                });
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e)=>{
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          
        return toaster("error", error.loading.excemption);
          // return e;
      })
  }




/**************************************************************************/

function examHandler(e) {
    var exam = e.target.value;
    document.getElementById("quantity").selectedIndex = 0;
    if (exam !== "") {
      var exams = value.exams.filter((e) => {
          return e.exam == exam
      })
      setValue({...value, exam: exam, quantity: '', amount: '', selectedExam: exams});
    } else {
      setValue({...value, exam: '', quantity: '', amount: '', selectedExam: []});
    }
  }


function quantityHandler(e) {
    if (value.selectedExam.length !== 0) {
        let quantity = e.target.value;
        let price = value.selectedExam[0].amount;
        let amount = price * quantity;
        setValue({...value, quantity: quantity, amount: amount})
    } 
  }


  function proceed(e) {
    e.preventDefault();
    if (value.selectedExam.length !== 1) return toaster("error", error.validation.emptyExam);     
    if (value.quantity == "") return toaster("error", error.validation.emptyQuantity);
    if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);

    let object = {
        "service": "Result Checker",
        "id" : value.selectedExam[0].id,
        "quantity": value.quantity,
        "exam": value.selectedExam[0].exam,
        "amount": value.amount,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }
   
}


  const PINHandler = (value) => {
    setPinModal(value);
  }


  useEffect(() => {
    scrollToTop();
    fetch_exams();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Result Checker'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}



  <SetPIN visibility={pinModal} state={PINHandler}/>

  <div className="col-xl-12 col-12">


    <div className="form-group styled-select mb-2" hidden={loading}>
        <select id="exam" onChange={examHandler} value={value.exam} className="form-control select-input">
        <option value=""> Please select  </option>
        { value.exams.length !== 0 ? value.exams.map((e, index) => { return <option value={e.exam} key={index}> {e.exam} </option> }) : ''}
        </select>
    </div>


    <div className="form-group styled-select mb-2" hidden={ value.selectedExam.length > 0 ? false : true }>
        <select id="quantity" onChange={ quantityHandler } className="form-control select-input">
        <option value=""> Select quantity</option>
        <option value="1"> 1 </option>
        <option value="2"> 2 </option>
        <option value="3"> 3 </option>
        <option value="4"> 4 </option>
        <option value="5"> 5 </option>
        </select>
    </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>


      <div className='mt-3 mb-0' style={{ padding: '0px 0px' }} hidden={ value.quantity !== '' ? false : true }>
        <div className="form-group mb-2">
          <input type="text" value={'₦'+value.amount} onChange={ (e) => {setValue({...value, amount: e.target.value})} } id="amount"
          readOnly placeholder="Enter amount" className="form-control input-area" /> 
        </div>
    </div>



<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
        Proceed <i className='fas fa-circle-arrow-right'></i>
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Exam