// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import 'react-loading-skeleton/dist/skeleton.css'
import error from '../../json/error.json';
import { ToastContainer } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

function Info() {


  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  


<div>


</div>
<ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}

        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-1 pt-3' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                
                <div className='flex justify-content-sb' style={{ alignItems: 'center'}}>                                                         
                    <div className='flex' style={{ alignItems: 'center' }}>                    
                        <div className='flex pb-1' style={{ flexDirection: 'column' }}> 
                        <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '19px' }}> Important ! </span> 
                        <span className='' style={{ marginLeft: '5px', fontSize: '14px', lineHeight: '26px' }}> 
                        
                        Dear esteemed customer, kindly be informed that your NIN (National Identification Number) is now necessary for the issuance of virtual accounts. 
                        If you already possess a virtual account, please ensure to link your NIN to prevent any potential disconnection by the CBN (Central Bank of Nigeria). 
                        <br/>
                        Rest assured that Telecomplug prioritizes the security of your information and will not share your details with any third party.


                        </span>                      
                        </div>
                    </div>
                    {/* <img src={"/images/fingerprint-verified.jpeg"} width={55} height={55} className='fw_img_shadow' style={{ borderRadius: '50%' }}/>  */}
                </div> 
                    





<div className='mt-2 mb-1' style={{ padding: '0px 0px' }}>

  <Link to={"/kyc/verify"}>  <button className='btn btn-primary mb-4 green_btn'> Link NIN </button> </Link>

          
</div>

<h4 className='mt-0'> I still don't have an NIN. ?</h4>
<div style={{ lineHeight: '26px' }}> No need to worry! Telecomplug has introduced an alternative method to fund your wallet without requiring your NIN. Simply click the button below to proceed with funding. </div>

<Link to={"/fund-wallet/dynamic-account"}>  <button className='btn btn-primary mb-4 red_btn'> Fund Wallet Without NIN </button> </Link>
              
            </div>
        </div>
        



       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default Info