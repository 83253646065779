import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react'
// import { Modal } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { baseURL, scrollToTop, toaster, user, storage} from '../../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
import error from '../../json/error.json';
import { PulseLoader } from 'react-spinners';
// import { Confirm } from 'react-st-modal';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

function SetPIN(props) {
    const navigate = useNavigate();

    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [inputPin, setInputPin] = useState('');
    // const [pinIcon, setPinIcon] = useState(true);

    function pinInputHandler(value) {
      var pinLength = value.length;
      var pinDOM = document.getElementById('input_pin');
      if(pinLength < 5) {
        setInputPin(value);
        if(pinLength === 4) { pinDOM.blur() }
  
      } else {
        pinDOM.blur();
      }
    }

    // console.log(props.visibility);

    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function save() {
        
        if(!loading) {
            if(inputPin !== "" && inputPin.length == 4) {
                dispatch({type: 'start'});
            axios.post(baseURL+"/user/set-pin", {
                pin: inputPin,
              },{    
                headers:{
                    'Authorization':`Bearer ${user().token}`,
                    'Content-Type': 'application/json'
                  },
                }).then((response) => {
        
                    if (response.data.status == "success" && response.data.name !== "") {
                        props.state(false);
                        dispatch({type: 'stop'});
                        setOpen(false);
                        return toaster('success', response.data.message);
                    } else if (response.data.status == "failed" && response.data.message !== "") {
                        dispatch({type: 'stop'});
                        return toaster("error", response.data.message);
                    } else {
                        return toaster("error", error.whoops);  
                    }
            
                }).catch((e)=> {
                  if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                  return toaster("error", error.whoops);
                    // console.log(e);
        
                })
            } else {
                return toaster("error", error.validation.requiredInput)
            }
        
            }
    }

    useEffect(() => setOpen(props.visibility));

  return (
    <div>
    {/* <Modal show={props.visibility} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false}  centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
          <img style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }} src={"/images/password-icon.png"} height={'60px'} width={"60px"} /> 
        </div>

        <h4 className='text-center'> 
    
        <span className='font-18' style={{ color: "#e00000", fontWeight: '600' }}> Transaction PIN 
         
        </span> </h4>      
        <p style={{ marginBottom: "15px" }} className="text-center">
        <i> Inorder to make your account more secure, you're required to set a 4 digit pin. 
            Any purchase made on our platform will be authorized using this PIN. </i>
        </p>


        <form className="form_pin mb-2 mt-1">
        <PinInput length={4} secret={pinIcon} type="numeric" autoComplete='off' inputMode="number" onChange={(value) => {setInputPin(value)}}/>
        <i onClick={() => {
          var state = !pinIcon;
          setPinIcon(state);
          }} className={pinIcon ? 'fa fa-eye' : 'fas fa-eye-slash'} style={{color: "#cbcbcb", fontSize: "15px", lineHeight: "40px", marginLeft: "10px" }}></i>
            </form>


        <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={void(0)} onClick={save} type="button" style={{ background: 'green', color: 'white', padding: '10px 20px', fontSize: '12ox' }} className="btn mb-3 border-0">
            
            { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loading ? '' : 'Set PIN' }

            </a>
        </div>

      </Modal.Body>
    </Modal> */}




{/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            <span className='font-18' style={{ color: "#e00000", fontWeight: '500' }}> Transaction PIN </span>
            <img style={{ borderRadius: "50%", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", float: 'right' }} src={"/images/password-icon.png"} height={'20px'} width={"20px"} /> 
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
            <span style={{ fontSize: '12px' }}> Inorder to make your account more secure, you're required to set a 4 digit pin. 
            Any purchase made on our platform will be authorized using this PIN. </span>
            </Typography>

       
        <div className='flex mb-2 mt-2' style={{ justifyContent: 'space-around', alignItems: 'center' }}>
          
          <span style={{fontSize: '13px'}}>Enter Pin: </span>        
            
            <span><input type={"text"} onChange={(e) => {pinInputHandler(e.target.value)}} autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} inputMode="numeric"          
                placeholder="****" value={inputPin} className="form-control set-pin" id="input_pin" />    
            </span> 

            <span><img src="/images/backhand-index-pointing-left.png" width={20}></img></span>

          </div>



            <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={void(0)} onClick={save} type="button" 
              style={{ background: 'green', color: 'white', padding: '10px 20px', fontSize: '12px', height: '44px' }} 
              className="btn mb-2 border-0 btn-block">            
            { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
            { loading ? '' : 'Set PIN' }

            </a>
        </div>

          </Box>
        </Fade>
      </Modal>





    </div>
  )
}

export default SetPIN