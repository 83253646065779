import React, { useRef } from 'react'
import Header from '../components/Header'
import Nav from '../components/Nav'
import { useEffect, useReducer, useState } from 'react';
import { baseURL, scrollToTop, toaster, user, siteName, storage, date} from '../functions/O3developer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadingReducer } from '../reducers/loading-reducer';



function Receipt() {


  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
    receipt();
  }, []);


  function receipt() {
    let data = storage("retrieve", "receipt", null, 'sessionStorage');
    // console.log(data);
    if (data) {
      if (typeof(data) === "object") {        
          setDetails(data);
          // console.log(data);
          // setNumberArray(data.number.split(','));
          // console.log(data.number.split(','));
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  
  }



  function TranxStatus(props) {
    let badgeClass = null;
    switch(props.status) {  
      case "success": badgeClass = "bg-green";
      break;
      case "reversed": badgeClass = "bg-red";
      break;
      case "cancel": badgeClass = "bg-red";    
      break;
      case "pending": badgeClass = "bg-warning";
       break;
      case "initiated": badgeClass = "bg-warning";
      break;
      default: badgeClass = "bg-warning";
    }
    return (
      <>
        <span className={"mt-0 badge border-0 " + badgeClass}>{props.status}</span>
      </>
    )
  
  }



  function TranxImg(props) {
    let receiptImg = null;
    let width = null;
    switch(props.status) {  
      case "success": receiptImg= "/images/checkmark.png"; width = '50';
      break;
      case "reversed": receiptImg = "/images/tranx-failed.png"; width = '40';
      break;
      case "cancel": receiptImg = "/images/tranx-failed.png"; width = '40'   
      break;
      case "pending": receiptImg = "/images/warning.png"; width = '50'
       break;
      case "initiated": receiptImg = "/images/warning.png"; width = '50'
      break;
      default: receiptImg = "/images/warning.png"; width = '50'
    }
    return (
      <>
        <img src={receiptImg} width={width} style={{ marginLeft: '10px' }}  />
      </>
    )
  
  }




  function redirectPINPage() {
    if (details.service == "Recharge Pin") {
      window.location.href = baseURL+"/recharge/pin/"+user().username+"/"+details.reference;      
    } else if (details.service == "Result Checker") {
      window.location.href = baseURL+"/exam/pin/"+user().username+"/"+details.reference;
    } else if (details.service == "Spectranet") {
      window.location.href = baseURL+"/spectranet/pin/"+user().username+"/"+details.reference;
    }
  }


  const [details, setDetails] = useState({});
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [numberArray, setNumberArray] = useState([]);

  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">  

    {/* <!-- Main content --> */}
        <section className="content mt-4" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
{/* ====================================================================== */}


  <div className="col-xl-12 col-12">


<div className='box mt-0 mb-2 select_list_text' style={{ color: '#475f7b', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', fontSize: '14px', borderRadius: '15px'  }}>

<div className="content-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2px 0' }}>
  <Link to='/'><div className='go_back go_back_style'> <i className='fas fa-arrow-left'></i> Go Home </div></Link>
  <div> 
    <TranxImg status={details.status}/>
  </div>
</div>

  <h2 className='text-center mt-3 mb-0' style={{ fontSize: '20px' }}>Transaction Receipt</h2>
  <div className='text-center'>
    
    <div className='' style={{ lineHeight: '23px', padding: '2px 5px', textAlign: 'center' }}>
      {details.description}
    </div>
      

    {/* Please get in touch with us if you have any complaints about this transaction. */}
  </div>
  
  {/* <div className='stamp'> {siteName} </div> */}

  <div className='flex justify-content-sb border_bottom receipt_content' style={{ marginTop: '-10px' }}>              
      <span>Service</span>
      <span>{details.service}</span>  
  </div>

  <div className='flex justify-content-sb receipt_content'>              
      <span>Reference</span>
      <span>{details.reference}</span>      
  </div>


  <table className='table table-striped table-bordered mb-1' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
        
        <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"> Amount: ₦{details.amount} </span></td>
          <td style={{ fontSize: '12px' }}> Status: <TranxStatus status={details.status} /> </td>
        </tr>
            
      </tbody>
    </table>

  

  <div className='flex justify-content-sb receipt_content'>              
      <span>Phone Number</span>
      <span>{details.phone_number}</span>                  
  </div>

  <table className='table table-striped table-bordered mb-1' style={{ width:'100%', marginBottom: '0px' }}>
        <tbody>
          <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"> Initial Bal. </span></td>
          <td style={{ fontSize: '12px' }}> ₦{details.balance_before} </td>
        </tr>

        <tr>
            <td style={{ fontSize: '12px' }}> Final Bal. </td>
            <td style={{ fontSize: '12px' }}> ₦{details.balance_after}  </td>
        </tr> 

        <tr style={{ background: 'white' }}>
          <td style={{ fontSize: '12px' }}> <span className="mt-0"> Date/Time </span></td>
          <td style={{ fontSize: '12px' }}> {date(details.date)} </td>
        </tr>      
      </tbody>
    </table>


  <div className='text-center receipt_content'>              
      {/* <span style={{ margin: '0px 10px' }}> Thank you for choosing us 🤝 </span>                                    */}
      {details.remark !== "null" && details.remark !== "" ? <span style={{ margin: '0px 10px' }}> {details.remark} </span>  : <span style={{ margin: '0px 10px' }}> Thank you for choosing us 🤝 </span> }
  </div>

  {/* <div className='text-center mb-3'><TranxImg status={details.status}/></div> */}


  </div>

{/* <div className='mt-0 mb-3' hidden={details.service == "Result Checker" || details.service == "Recharge Pin" || details.service == "Spectranet" ? true : false} style={{ padding: '0px 0px' }}>
<div className='text-center mb-1'> Please get in touch with us if you have any complaints about this transaction. </div>
<div className='flex justify-content-sb'>
<button className='btn btn-primary receipt_btn' style={{ width: '50%', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', background: '#007000' }}>Print <i className='fas fa-print'></i></button>
<button className='btn btn-primary receipt_btn' style={{ width: '50%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', background: '#cb0000' }}>Download <i className='fas fa-download'></i></button>
</div>
</div> */}

<div style={{ display: 'flex', justifyContent: 'center' }}><button hidden={details.service == "Result Checker" || details.service == "Recharge Pin" || details.service == "Spectranet" ? false : true} onClick={() => redirectPINPage() } className='btn btn-primary receipt_btn' style={{ width: '50%', borderRadius: '10px',  background: '#007000' }}>View PIN <i className="fa fa-eye" aria-hidden="true"></i></button></div>
  {/* <a hidden={details.service == "Result Checker" || details.service == "Recharge Pin" || details.service == "Spectranet" ? false : true} href={void(0)} className="btn btn-sm btn-l rounded-s font-600 mb-3" onClick={() => redirectPINPage() } style={{ width: "100%", backgroundColor: "green" }}>View PIN <i className="fa fa-eye" aria-hidden="true"></i></a> */}

  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}



</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Receipt