import React, { useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import SetPIN from '../components/modals/SetPIN';

function Smile() {
  const navigate = useNavigate();


  const fetch_plans = async () => {
    dispatch({type: 'start'});
      axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
      await axios.post(baseURL+"/smile",  {
    }, {
        headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          PINHandler(!response.data.pin);
          // console.log(response.data);
              setValue({
                ...value,
                plans: response.data.smile,
                });
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e) => {
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }



/**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState(''); 
    const [isValidate, setIsValidate] = useState(false);
    const [value, setValue] = useState({
          phoneNumber: '',
          img: '/dashboard/images/smile.png',
          imgWidth: '35px',
          plans: [],
          plan_id: '',
          selectedPlan: [],
          smileNumber: '',
    });

    const [customerName, setCustomerName] = useState('');
    const [modalShow, setModalShow] = useState(false);

/**************************************************************************/

function smileHandler(e) {
    var plan_id = e.target.value;
    if (plan_id !== "") {
      var plan = value.plans.filter((e) => {
          return e.id == plan_id
      });
      setValue({...value, plan_id: plan_id, selectedPlan: plan});
    } else {
      setValue({...value, plan_id: '', selectedPlan: []});
    }
  }

/**************************************************************************/

  function notValidated() {
    setValidateLoading(false);
    setIsValidate(false);
  }
  

  function validate() {
    setValidateLoading(true);
    if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
    if (value.smileNumber == "") return toaster("error", error.validation.emptySmile);
   
    axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
    axios.post(baseURL+"/smile/validate", {
      id: value.selectedPlan[0].id,
      smileNumber: value.smileNumber,
    }).then((response) => {        
          if (response.data.status == "success" && response.data.name !== "") {
            setCustomerName(response.data.name);
            setModalShow(true);
            setValidateLoading(false);
          } else if (response.data.status == "failed" && response.data.message !== "") {
            notValidated();
            return toaster("error", response.data.message);
          } else {
            notValidated();
            return toaster("error", error.whoops);  
          }

      }).catch((e) => {
          notValidated();
          return toaster("error", error.loading.excemption);
          // console.log(e);
          // return e;
      })
  
  }
  
  

    /**************************************************************************/
    
    function proceed(e) {
        e.preventDefault();
        if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
        if (value.smileNumber == "") return toaster("error", error.validation.emptySmile);
        if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
        if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
        const amount = value.selectedPlan[0].amount;
        if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance);
  
        if (isValidate == false) { 
          validate();
          setIsValidate(true);
          return;
        }
  
        if (customerName !== "") {
        let object = {
            "service": "Smile Bundle",
            "id" : value.selectedPlan[0].id,
            "number": value.phoneNumber,
            "smileNumber": value.smileNumber,
            "amount": value.selectedPlan[0].amount,
            "plan": value.selectedPlan[0].plan,
            "customerName": customerName,
        };
        object = JSON.stringify(object);
        if(storage("save", "invoice", object, "sessionStorage")) {
            navigate("/invoice");
        } else {
            return alert(error.whoops);
        }
  
      } else {
        return toaster("error", error.validation.emptyCustomerName);
      }
           
    }
      
    const PINHandler = (value) => {
      setPinModal(value);
    }
  
    const hideModal = () => {
      setModalShow(false);
      setIsValidate(false);
    }
    
    const btnTxt = isValidate === true ? "Proceed" : "Validate";
    const [validateLoading, setValidateLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    fetch_plans();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Smile'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}




  <SetPIN visibility={pinModal} state={PINHandler}/>


{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
            <img src="/images/smile.png" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            /> 
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "1px" }}>
        <i> Please double-check that the above name corresponds to the card holder's name. </i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '0px' }}>
            <tbody>
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Smile Number </b> </td>
                        <td style={{ fontSize: '12px' }}> <span style={{ color: "blue" }}> <b>  {value.selectedPlan.length == 1 ? value.smileNumber : ''} </b> </span> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "red" }}><b> ₦{value.selectedPlan.length == 1 ? value.selectedPlan[0].amount : ''} </b></span> </td>
                     </tr>
                    <tr>
                        <th scope='row' style={{ fontSize: '12px' }}> Package </th>
                        <td colSpan='2' style={{ fontSize: '12px' }}> <span style={{ color: "green"}}><b> {value.selectedPlan.length == 1 ? value.selectedPlan[0].plan : ''} </b> </span> </td>
                    </tr>
            </tbody>
        </table>
        
        <div className="row mb-0 mt-3 text-center">
          <div className="col-6">
          <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={hideModal} style={{ color: "white", backgroundColor: "red", fontSize: '14px', padding: "12px 16px" }}>Cancel </a>
          </div>
          <div className="col-6">
          <a href="#" className="btn btn-primary mb-3 border-0 btn-block" onClick={proceed} style={{ color: "white", backgroundColor: "green", fontSize: '14px', padding: "12px 16px" }}>Yes, it's correct</a>
          </div>
        </div>

      </Modal.Body>
    </Modal>





  <div className="col-xl-12 col-12">



    <div className="form-group styled-select mb-0" hidden={loading}>
        <select id="plan" onChange={smileHandler} value={value.plan_id} className="form-control select-input">
        <option value=""> Please select  </option>
        { value.plans.length !== 0 ? value.plans.map((e, index) => { return <option value={e.id} key={index}> {e.plan} </option> }) : ''}
        </select>
    </div>



      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>



      <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={loading}>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.smileNumber} onChange={(e) => setValue({...value, smileNumber: e.target.value})} 
            placeholder='Enter your smile account number' className="form-control input-area" id="number" /> 
        </div>
    </div>



      <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={loading}>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} 
            placeholder='Enter phone number' className="form-control input-area" id="number" /> 
        </div>
    </div>


<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
    
    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
    { validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
        { validateLoading ? '' : btnTxt }
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Smile
