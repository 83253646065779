import React, { useEffect, useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import Nav from '../components/Nav';
import { Modal, Button } from 'react-bootstrap';
import { baseURL, scrollToTop, toaster, user, storage, skeletonBaseColor} from '../functions/O3developer';
import ActionSheet from "actionsheet-react";
import error from '../json/error.json';
import Top from '../components/service/Top';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { loadingReducer } from '../reducers/loading-reducer';
import { PulseLoader } from 'react-spinners';
import SetPIN from '../components/modals/SetPIN';

function Spectranet() {
  const navigate = useNavigate();


  const fetch_plans = async () => {
    dispatch({type: 'start'});
      await axios.post(baseURL+"/spectranet",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
      if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          // console.log(response.data);
            PINHandler(!response.data.pin);
              setValue({
                ...value,
                plans: response.data.spectranet,
                });
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }

      }).catch((e) => {
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }


/**************************************************************************/
const [pinModal, setPinModal] = useState(false);
const [loading, dispatch] = useReducer(loadingReducer, false);
const [wallet, setWallet] = useState(''); 
const [value, setValue] = useState({
      phoneNumber: '',
      img: '/dashboard/images/spectranet-banner.jpg',
      imgWidth: '37px',
      imgHeight: '35px',
      plans: [],
      plan_id: '',
      selectedPlan: [],
      amount: '',
      quantity: '',
});



/**************************************************************************/

function planHandler(e) {
    var plan_id = e.target.value;
    if (plan_id !== "") {
      var plan = value.plans.filter((e) => {
          return e.id == plan_id
      });
      setValue({...value, plan_id: plan_id, selectedPlan: plan});
    } else {
      setValue({...value, plan_id: '', selectedPlan: []});
    }
  }



  function proceed(e) {
    e.preventDefault();
    if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
    if (value.smileNumber == "") return toaster("error", error.validation.emptySmile);
    if (value.phoneNumber == "") return toaster("error", error.validation.emptyPhoneNumber);
    if (value.phoneNumber.length !== 11) return toaster("error", error.validation.invalidPhoneNumber);
    const amount = value.selectedPlan[0].amount;
    if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance);
    // if (value.selectedPlan[0].amount < wallet || value.selectedPlan[0].amount !== wallet) return toaster("error", error.lowBalance)
//
    let object = {
        "service": "Spectranet",
        "id" : value.selectedPlan[0].id,
        "number": value.phoneNumber,
        "amount": value.selectedPlan[0].amount,
        "plan": value.selectedPlan[0].plan,
    };
    object = JSON.stringify(object);
    if(storage("save", "invoice", object, "sessionStorage")) {
        navigate("/invoice");
    } else {
        return alert(error.whoops);
    }
       
}


  const PINHandler = (value) => {
    setPinModal(value);
  }


  useEffect(() => {
    scrollToTop();
    fetch_plans();
    return () => axios.CancelToken.source();
  },[]);
  

/**************************************************************************/


  return (
    <div>

<div className="wrapper">

<Header/>

<Nav/>

<ToastContainer/>


{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
  <div className="container-full">
  

<Top title='Spectranet'/>

<div>


</div>


    {/* <!-- Main content --> */}
        <section className="content mt-2" style={{ padding: '0px 10px 0px 10px' }}>
            <div className="row">	
    
    {/* <a href='#' class="btn btn-circle btn-secondary mb-5 position-relative"> <i  className='mdi mdi-arrow-left-bold font-size-20'></i> </a> */}

        {/* ====================================================================== */}



  <SetPIN visibility={pinModal} state={PINHandler}/>

  <div className="col-xl-12 col-12">



    <div className="form-group styled-select mb-0" hidden={loading}>
        <select id="plan" onChange={planHandler} value={value.plan_id} className="form-control select-input">
        <option value=""> Please select  </option>
        { value.plans.length !== 0 ? value.plans.map((e, index) => { return <option value={e.id} key={index}> {e.plan} </option> }) : ''}
        </select>
    </div>



      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>


      <div className='mt-2 mb-0' style={{ padding: '0px 0px' }} hidden={loading}>
        <div className="form-group mb-2">
          <input type="number" autoCorrect='off' autoComplete='off' pattern={'[0-9]*'} 
          value={value.phoneNumber} onChange={(e) => setValue({...value, phoneNumber: e.target.value})} 
            placeholder='Enter phone number' className="form-control input-area" id="number" /> 
        </div>
    </div>


<div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>

    <button className='btn btn-primary mb-4 tp_btn'hidden={ loading } onClick={proceed}>
        Proceed <i className='fas fa-circle-arrow-right'></i>
      </button>

      <span hidden={ !loading } className='skeleton_shadow mb-4'> <SkeletonTheme height={55}> <Skeleton count={1} baseColor={skeletonBaseColor} className='mb-4' /> </SkeletonTheme> </span>
      <div hidden={ !loading } className='text-center'>
        <PulseLoader color={"#2c3e50"} loading={true} size={8}/>
        </div>
          
</div>





  </div>



                                                             
            </div>						
        </section>
    {/* <!-- /.content --> */}
  </div>
</div>
{/* <!-- /.content-wrapper --> */}




</div>
{/* <!-- ./wrapper --> */}

    </div>
  )
}

export default Spectranet
