import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from '../components/Header'
import Nav from '../components/Nav'
import Carousel from 'nuka-carousel/lib/carousel'
import { Link } from 'react-router-dom'
import AlertDialog from '../components/material-ui/Dialogue'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { loadingReducer } from '../reducers/loading-reducer';
import { baseURL, scrollToTop, user, storage, siteName, backgroundColor, sweetalert } from '../functions/O3developer';
import WalletCard from "../components/Home/WalletCard";
import Notification from "../components/modals/Notification";
import Welcome_Social from "../components/Home/Welcome_Social";
import { FloatingWhatsApp } from "react-floating-whatsapp";


import swal from 'sweetalert';

function Home() {
  

useEffect(() => { 
  scrollToTop();
  AOS.init();
  dashboardRequest();
  
} , []);



const navigate = useNavigate();
const [loading, dispatch] = useReducer(loadingReducer, false);
const [wallet, setWallet] = useState('');  
const [commission, setCommission] = useState('');
const [verification, setVerification] = useState('');
const [notification, setNotification] = useState('');
const [whatsappNo, setWhatsappNo] = useState('');
const [whatsappLink, setWhatsappLink] = useState('');
const [bonus, setBonus] = useState('');
const refLink = window.location.origin +'/register?ref='+ user().username;

const dashboardRequest = async () => {
  dispatch({type: 'start'});
  await axios.post(baseURL+"/user/dashboard",  {
}, {    
  headers:{
      'Authorization':`Bearer ${user().token}`,
      'Content-Type': 'application/json'
  }, 
}).then((response) => {
      // console.log(response.data);
    if (response.data.status == "failed" && response.data.message !== "") {
      // return toaster("error", response.data.message);
    } else if (response.data.status == "success") {    
      
        setWallet(response.data.user.wallet);
        setCommission(response.data.user.commission);
        setVerification(response.data.user.verification);
        // setNotification(response.data.notification.message);
        setBonus(response.data.referral.commission);
        setWhatsappNo(response.data.whatsapp.number);
        setWhatsappLink(response.data.whatsapp.link);
        if (response.data.notification.message !== null && response.data.notification.visibility == "show") {
          // sweetalert(response.data.notification.message);              
          swal(response.data.notification.message, {
            className: "sweetalert",
          });
        }
    }
  }).catch((e) => {   
    if (e.response !== undefined) {
      if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
          storage("remove", "data", null, "localStorage");
          return navigate("/login");
      }
    }
      // return toaster("error", error.loading.excemption);
      // return e;
  })
}




  return (
    <div>
        

<div className="wrapper">

  <Header/>

  <Nav/>



{/* <AlertDialog/> */}



{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
    <div className="container-full">
      {/* <!-- Content Header (Page header) -->	   */}

      <Welcome_Social user={user()}/>

            {/* <Notification message={notification}/> */}


      {/* <!-- Main content --> */}
          <section className="content" style={{ padding: '0px 10px 0px 10px' }}>
              <div className="row">	

                  <div className="col-xl-12 col-12">
                      <div className="row">
                                            
                   
          <div className="col-12 col-lg-4">
             <WalletCard wallet={wallet} commission={commission}/>
                  </div>




        <div className="col-12 col-lg-4">
          <div style={{ margin: '5px 0px' }}>
            <Carousel wrapAround={true} autoplay={true} renderCenterLeftControls={({ previousSlide }) => ( <></> )} renderCenterRightControls={({ nextSlide }) => ( <></> )}>

            <a href={"whatsapp://send?text=Hello, register on "+ refLink +" to start buying cheap data and airtime today!"}> <img className='carousel_style custom_card_shadow' src="/images/telecomplug-refer-and-earn.jpg" /> </a>
              {/* <img className='carousel_style custom_card_shadow' src="/images/telecomplug-airtime-to-cash.jpg" />               */}
              <img className='carousel_style custom_card_shadow' src="/images/telecomplug-buy-data.jpg" />
              {/* <img className='carousel_style custom_card_shadow' src="/images/become-telecomplug-retailer.jpg" /> */}
            </Carousel>    
          </div>
      </div>



        <div className="col-12 col-lg-4 d-none d-lg-block mt-2">
              <div className="box box-body bg-hexagons-dark pull-up p-10 card_style" style={{ borderTop: "2px solid" }}>
                <div className="media align-items-center p-0" style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                  <div className="text-center" style={{ margin: "0 1px" }}>
                  
                  <div style={{ border: "1px dashed #fff", borderRadius: "50%", padding: "10px", boxShadow: "rgb(17 12 46 / 15%) 0px 48px 100px 0px" }}>
                      <img src="/images/exchange.png" width="35px" />
                  </div>
                  
                  
                  </div>
                  <div style={{ margin: "0 1px" }}>
                    <h4 className="no-margin text-bold" style={{ fontFamily: "'Nunito Sans', sans-serif", fontWeight: "bolder", color: "#475f7b" }}>
                    Referral
                    </h4>
                    
                  </div>
                </div>
                <div className="flexbox align-items-center mt-10" style={{ justifyContent: "flex-end" }}>
                                          
                  
                  <div className="text-right">
                    <p className="no-margin font-weight-600"><span className="text-dark font_nunito">Amount Earned</span></p>
                    <p className="no-margin" style={{ fontSize: "18px" }}><b>₦0.00</b></p>
                  </div>
                </div>
              </div>			
            </div>          




    
            {/* <div className="col-12 col-lg-4 d-none d-lg-block mt-2">
                <div className="box box-body pull-up p-10 card_style">
                    <div className="d-flex justify-content-between">
                      <div className="p-0">
                        <div className="text-center">
                          <a href="#"><i className="cc LSK mr-5" title="LSK"></i></a>
                        </div>
                        <div>
                          <h3 className="no-margin text-bold">Lisk</h3>
                          <span>Trading</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p className="no-margin font-weight-600"><span className="text-warning">$7.9</span> / $7.0</p>
                          <p className="no-margin">Sponsored</p>
                        </div>
                        <div className="mt-10">
                          <p className="no-margin font-weight-600"><span className="text-warning">74%</span></p>
                          <p className="no-margin">5d left</p>
                        </div>
                      </div>
                  </div>
                </div>			
            </div> */}


                      </div>	
                                
                  </div>
 
 
                  

    <div className="col-xl-12 col-12">
			<div className="row">
      <section className="content" style={{ padding: '0px 15px 0px 15px' }}>
			  
				<div className="box custom_card_shadow" style={{ backgroundColor: "#eef2f480", boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
        {/* <div class="box-body"> */}
        <div className="row">
                                
          <div className="col-4 col_padding">
                <div className="bg-white service-card" style={{ borderTopLeftRadius: '10px' }}>

                  <div className="text-center pt-4">
                    <Link to="/data">
                      <img className="service-grid" src="/images/wifi.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><Link to="/data" className='grid-card-text'>Buy Data</Link></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}


          <div className="col-4 col_padding">
                <div className="bg-white service-card">

                  <div className="text-center pt-4">
                    <Link to="/airtime">
                        <img className="service-grid" src="/images/telephone.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><Link to="/airtime" className='grid-card-text'>Buy Airtime</Link></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white service-card" style={{ borderTopRightRadius: '10px' }}>

                  <div className="text-center pt-4">
                    <Link to="/cable">
                      <img className="service-grid" src="/images/streaming-tv-app.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><Link to="/cable" className='grid-card-text'>Cable Sub</Link></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>



              {/* ---------------------------------- */}


              <div className="col-4 col_padding">
                <div className="bg-white service-card">

                  <div className="text-center pt-4">
                    <Link to="/recharge-pin">
                      <img className="service-grid" src="/images/printer.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><a href="#" className='grid-card-text'>Recharge Card</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}

          <div className="col-4 col_padding">
                <div className="bg-white service-card">

                  <div className="text-center pt-4">
                    <Link to="/electricity">
                      <img className="service-grid" src="/images/bulb.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><Link to="/electricity" className='grid-card-text'>Electricity</Link></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white service-card">

                  <div className="text-center pt-4">
                  <Link to="/exam">
                      <img className="service-grid" src="/images/exam.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><a href="#" className='grid-card-text'>Exam PIN</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

{/* ----- */}


      <div className="col-4 col_padding">
                <div className="bg-white service-card" style={{ borderBottomLeftRadius: '10px' }}>

                  <div className="text-center pt-4">
                  <Link to="/smile">
                      <img className="service-grid" src="/images/smile.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><a href="#" className='grid-card-text'>Smile</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}

          <div className="col-4 col_padding">
                <div className="bg-white service-card">

                  <div className="text-center pt-4">
                  <Link to="/bulk-sms">
                      <img className="service-grid" src="/images/chatting.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><a href="#" className='grid-card-text'>Bulk SMS</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white service-card" style={{ borderBottomRightRadius: '10px' }}>

                  <div className="text-center pt-4">
                  <Link to="/spectranet">
                      <img className="service-grid" src="/images/spectranet-device.png" alt=""/>
                    </Link>
                    <h5 className="mt-3 mb-0 pb-1 service-font"><a href="#" className='grid-card-text'>Spectranet</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>





              </div></div>






              {/* <div>
                <div style={{ background: backgroundColor, height: '150px' }}>

                </div>
        
              </div> */}


				  {/* <div className="box-body">
					<p>Basic Box without header</p>
					<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.</p>
				  </div> */}
		
          {/* </div> */}
          </section>
        </div>



    </div>


  {/* ====================================================================== */}


                  
                  
      {/* <div className="content">

      <h3 style={{ fontFamily: "'Abril Fatface', cursive", fontSize: "1.5rem", marginTop: "40px", marginBottom: "40px" }}>
              Our Services 👇
            </h3>  


          <div className="row">
                                
               <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="http://localhost:3000/dashboard/images/data.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Data</a></h5>
                          <span>Topup</span>
                        </div>
                      </div>
                    </div>	
                    
                    
                    
                    
                   
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">
  
                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="http://localhost:3000/dashboard/images/airtime.svg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Airtime</a></h5>
                          <span>Topup</span>
                        </div>
                      </div>
                    </div>
                   
                    
                    
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                    
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">
                        <div className="flexbox align-items-center">
                          <label className="toggler toggler-yellow">
                            <input type="checkbox"/>
                            <i className="fa fa-star"></i>
                          </label>
                          <div className="dropdown">
                            <a data-toggle="dropdown" href="#" aria-expanded="false"><i className="fa fa-cog"></i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-user"></i> Profile</a>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-comments"></i> Messages</a>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-phone"></i> Call</a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-remove"></i> Remove</a>
                            </div>
                          </div>
                        </div>
                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    

                    
                  </div>
              </div>	 */}
                  
                  
                                        
              </div>						
          </section>
      {/* <!-- /.content --> */}
    </div>
</div>
{/* <!-- /.content-wrapper --> */}

<FloatingWhatsApp
    phoneNumber={whatsappNo !== '' ? whatsappNo : null}
    accountName={siteName}
    statusMessage="Customer support"
    avatar = "/images/Telecomplug-logo-transaparent.png"
    chatMessage="Hello, What can I do to assist you?"
    allowClickAway
    notification
    notificationDelay={60000} // 1 minute
    notificationSound
/>


<footer className="main-footer text-center">  
    &copy; 2024 <a href="#">Telecomplug</a>. All Rights Reserved.
</footer>


</div>
{/* <!-- ./wrapper --> */}


    </div>
  )
}

export default Home