import React from 'react'

function ValidateCard(props) {
  return (
    <div>
        
        <div style={{ fontSize: '12px', lineHeight: '24px', border: "1px solid #f0f8ff7d", padding: "15px 10px", borderRadius: "5px", textAlign: "center", backgroundColor: "#f0f8ff7d", marginBottom: '10px', boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px'  }} hidden={props.hideResult}>
        <strong className="color-black font-400 ">
            <i className="fa fa-check-circle rounded-s" style={{ color: 'green' }}></i> { props.validNumbers }  valid phone number(s) found.</strong> <br/>

        <strong className="color-black font-400">
        <i className="fa fa-times-circle rounded-s" style={{ color: 'red' }}></i> { props.InvalidNumbers }  Invalid number(s) were removed.</strong> <br/>

        <strong className="color-black font-400">
        <i className="fa fa-times-circle rounded-s" style={{ color: 'red' }}></i> { props.duplicateNumbers } duplicate number(s) were removed.</strong>
    </div>

    </div>
  )
}

export default ValidateCard;