import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams   } from "react-router-dom";
import axios from 'axios';
import { auth, toaster, baseURL, siteLogo, siteName } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

function Register() {


    const [searchParams] = useSearchParams();
    const referral = searchParams.get("ref") !== null ? searchParams.get("ref") : '';
    const [registerState, setRegisterState] = useState({
        firstname: '',
        lastname: '',
        email: '',
        number: '',      
        username: '',
        password: '',
        referral: '',
        confirm_password: '',
    });
      const [loading, setLoading] = useState(false);
  
      const navigate = useNavigate();
      const formHandler = async (event) => {
        event.preventDefault();
        if (!loading) {
        if (registerState.firstname == "")  return toaster("error", "The firstname field cannot be empty");
        if (registerState.lastname == "")  return toaster("error", "The lastname field cannot be empty");
        if (registerState.username == "")  return toaster("error", "The username field cannot be empty");
        if (registerState.email == "")  return toaster("error", "The email field cannot be empty");
        if (registerState.number == "")  return toaster("error", "The number field cannot be empty");
        if (registerState.number.length !== 11)  return toaster("error", "Please input a valid phone number");
        if (registerState.password == "")  return toaster("error", "The password field cannot be empty");
        if (registerState.username.length < 2)  return toaster("error", "Invalid username supplied ");
        if (registerState.password.length < 2)  return toaster("error", "Invalid password supplied ");
        setLoading(true);
        axios.defaults.headers.common = {'Content-Type': 'application/json'}
        await axios.post(baseURL+"/user/register",  registerState).then((response) => {
  
          if (response.data.code !== 200 && response.data.status == "failed") {
            setLoading(false);
            return toaster("error", response.data.message);
          } else if (response.data.code == 200 && response.data.status == "success") {
            setLoading(false);
            return navigate("/login", { 
              state: { message: 'Your registration was successful. We are glad to have you 🤝' }  
            });
          } else {
            setLoading(false);
          }
  
          }).catch((e)=>{
              setLoading(false);
              alert(error.whoops)
              // return e;
          })
        }
      }
  
        useEffect(() => {           
            if (auth() == true) {
                return navigate("/")
            } else {        
                setRegisterState({...registerState, referral})
            }
       } , []);


  return (
    <div>
        <ToastContainer/>
        <div className='pt-5 mb-5' style={{ marginTop: '20px' }}>
<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>
        {/* <div className="auth-2-outer row align-items-center h-p100 m-0"> */}

		<div className="auth-card bg-white">
		  <div className="auth-logo font-size-30 pt-4">
			<img src={siteLogo} width={150}/>
			{/* <a href="../index.html" className="text-dark"><b>Telecom</b>plug</a> */}
		  </div>
          
		  <div className="auth-body" style={{ padding: '5px 12px 10px' }}>
			<p className="auth-msg text-black-50"><i>Register with us now and start enjoying the best telecom service.</i></p>

			<form method="post" className="form-element" onSubmit={formHandler}>

			  <div className="form-group has-feedback">
				<input type="text" className="form-control login-input" value={registerState.firstname} onChange={e => setRegisterState({...registerState, firstname: e.target.value})} placeholder="Enter your firstname"/>
				<span className="fas fa-arrow-left form-control-feedback text-dark mt-2"></span>
			  </div>

              <div className="form-group has-feedback">
				<input type="text" className="form-control login-input" value={registerState.lastname} onChange={e => setRegisterState({...registerState, lastname: e.target.value})} placeholder="Enter your lastname"/>
				<span className="fas fa-arrow-left form-control-feedback text-dark mt-2"></span>
			  </div>

			  <div className="form-group has-feedback">
				<input type="text" className="form-control login-input" value={registerState.username} onChange={e => setRegisterState({...registerState, username: e.target.value})} placeholder="Enter your username"/>
				<span className="fas fa-user form-control-feedback text-dark mt-2"></span>
			  </div>
              

              <div className="form-group has-feedback">
				<input type="email" className="form-control login-input" value={registerState.email} onChange={e => setRegisterState({...registerState, email: e.target.value})} placeholder="Enter your email address"/>
				<span className="fas fa-at form-control-feedback text-dark mt-2"></span>
			  </div>

              <div className="form-group has-feedback">
				<input type="number" className="form-control login-input" value={registerState.number} onChange={e => setRegisterState({...registerState, number: e.target.value})} placeholder="Enter your phone number"/>
				<span className="fas fa-phone form-control-feedback text-dark mt-2"></span>
			  </div>

			  <div className="form-group has-feedback">
				<input type="password" className="form-control login-input" value={registerState.password} onChange={e => setRegisterState({...registerState, password: e.target.value})} placeholder="Enter your password"/>
				<span className="fas fa-key form-control-feedback text-dark mt-2"></span>
			  </div>

			  <div className="form-group has-feedback">
				<input type="password" className="form-control login-input" value={registerState.confirm_password} onChange={e => setRegisterState({...registerState, confirm_password: e.target.value})} placeholder="Confirm your password (re-type)"/>
				<span className="fas fa-key form-control-feedback text-dark mt-2"></span>
			  </div>

			  <div className="row">

              
                
                

				<div className="col-12 text-center mt-3">
				<button className='btn btn-primary mb-4 tp_btn'>
				{ loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 				{ loading ? '' : 'Register ' }
				{ loading ? '' : <i className='fas fa-user-plus'></i> }
      			</button>
				  {/* <button type="submit" className="btn btn-rounded my-20 btn-success">SIGN IN</button> */}
				</div>
                
			  </div>
			</form>


			<div className="margin-top-30 text-center text-dark">
				<p>Already have an account? <Link to="/login" className="text-info m-l-5">Sign In</Link></p>
			</div>

		  </div>
		</div>
	
	</div> </div> </div> 
    </div>

    <FloatingWhatsApp
      phoneNumber={"2349014463362"}
      accountName={siteName}
      statusMessage="Customer support"
      avatar = "/images/Telecomplug-logo-transaparent.png"
      chatMessage="Hello, What can I do to assist you?"
      allowClickAway
      notification
      notificationDelay={60000} // 1 minute
      notificationSound
    /> 


    </div>
  )
}

export default Register