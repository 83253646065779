// import React from 'react'
import axios from 'axios';
import { useEffect, useState, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, user, storage, toaster, siteLogo, skeletonBaseColor } from '../../functions/O3developer';
import Header from '../../components/Header';
import Nav from '../../components/Nav';
import error from '../../json/error.json';
import { loadingReducer } from '../../reducers/loading-reducer';
import { ToastContainer } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import { PulseLoader } from 'react-spinners';

function VirtualAccount() {

    const navigate = useNavigate();

    async function load() {
      dispatch({type: 'start'});      
      
        await axios.post(baseURL + "/fund-wallet/virtual-account",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
        }).then((response) => {
            // console.log(response.data);
        if (response.data.message == "Verification required" || response.data.message == "Invalid NIN") {
          dispatch({type: 'stop'});
          return navigate("/kyc/verification-info");

        } else if (response.data.status == "success" && response.data.verification == true) {          
              setAccounts(response.data.data);
              setCharge(response.data.charge);
              setChargeType(response.data.chargeType);
              dispatch({type: 'stop'});
        } else if (response.data.status == "success" && response.data.verification == false) {
          
              return navigate("/kyc/verification-info");

        } else if(response.data.status == "failed" && response.data.message !== "") {            
              return toaster("error", response.data.message);      
              
        } else if (response.data.status == "failed" && response.data.message !== "") {

          return toaster("error", response.data.message);
        
        } else {
          return toaster("error", error.loading.excemption);    
        }

        }).catch((e)=>{
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }
            return toaster("error", error.loading.excemption);
        })
    }



    const [accounts, setAccounts] = useState([]);
    const [charge, setCharge] = useState('');
    const [chargeType, setChargeType] = useState('');
    const [loading, dispatch] = useReducer(loadingReducer, false);
    
    useEffect(() => { load(); return () => axios.CancelToken.source(); }, []);

    function AccountCard(props) {

      let imgBaseURL = "/images/";
      let bankImg = siteLogo;
      const bank = props.bankName;
      if (bank == "Wema bank") {
        bankImg = imgBaseURL + "wema-bank-transfer-code.jpg";
      } else if (bank == "Moniepoint Microfinance Bank") {
        bankImg = imgBaseURL + "moniepoint-logo.svg";
      } else if (bank == "Sterling bank") {
        bankImg = imgBaseURL + "sterling_ussd.jpg";      
      } else if (bank == "Fidelity bank") {
        bankImg = imgBaseURL + "Fidelity-Bank.jpg";
      } 



      return (
      <div className='box p-0 mb-3' style={{ cursor: 'context-menu', borderRadius: '15px'}}>
        {/* <div style={{ top: '0px', position: 'absolute', background: 'linear-gradient(45deg, rgba(0, 0, 0, 0.71), transparent)', width: '75px', height: '25px', left: '0px', borderRadius: '0%' }}> Copy </div> */}
        <div className='flex justify-content-sb'>
          <div></div>
            <div className='mt-1' style={{ marginLeft: '5px', fontSize: '15px' }}> {props.bankName} </div> 
            <CopyToClipboard text={props.accountNumber} onCopy={() => toaster("success", "Account number successfully copied to clipboard")}>
            <div className='text-center custom_card_shadow pt-1' style={{ fontSize:'12px', color: 'white', background: 'linear-gradient(45deg, #297bb1, #2b465c)', fontWeight: '500', width: '60px', height: '25px', borderTopRightRadius: '15px', borderBottomLeftRadius: '15px' }}> Copy <i className='fa fa-copy text-white'></i> </div>
            </CopyToClipboard>
        </div>

              <div className='flex justify-content-sb p-2'> 

              <img src={bankImg} className='fw_img_shadow' style={{ borderRadius: '10px' }} width={50} height={40}/>

                  <div>
                    <div style={{ marginLeft: '5px', fontSize: '13px', lineHeight: '22px', }}> 
                    Account Number: {props.accountNumber}
                    </div>
                    <div style={{ marginLeft: '5px', fontSize: '13px', lineHeight: '22px' }}> 
                    Name: {user().firstname} {user().lastname}
                    </div> 
                  </div>  
                  
                  <div style={{ marginRight: '5px', fontSize: '12px', lineHeight: '22px' }}> 
                  <div>Charge 👇</div>
                  <div className='text-right'>{props.chargeType == "VAT" ? "₦" : ""}{props.charge}{props.chargeType == "FLAT" ? "%" : ""}</div>
                    
                  </div> 

                </div>
          
        </div>
      );

    }
    

  return (
    <div>
        
        <div className="wrapper">
            <Header/>
            <Nav/>


            <div className="content-wrapper">
  <div className="container-full">
  
    <ToastContainer/>
{/*  bg-hexagons-dark */}
{/* <!-- Main content --> */}
    <section className="content" style={{ padding: '0px 10px 0px 10px', marginTop: '25px' }}>
        <div className="row">	
          <div className="col-xl-12 col-12" style={{ padding: '5px' }}>
          
        {/* ---------------------------------------------------------- */}




        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            <div className='box mb-0 pb-2 pt-2' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}>
                                                                                                             
              <span className='fw-header-txt' style={{ marginLeft: '5px', fontSize: '17px' }}> Instant Funding </span> 
              <span className='' style={{ marginLeft: '5px', fontSize: '13px', lineHeight: '25px' }}> 
              Send money 💰 to the below account number and your wallet will get credited automatically. 
              Bearing in mind that some charge will be deducted from the amount you sent. - Thanks for choosing us 🤝.
              </span>                      
                                    
                <div className='mt-2 mb-2' style={{ padding: '0px 0px' }}>
                  <img src={"/images/monnify.png"} width={80}/>                        
                </div>              
            </div>
        </div>
        


        <div className='mt-0 mb-3' style={{ padding: '0px 0px' }}>
            {/* <div className='box mb-0 pb-1 pt-1' style={{ cursor: 'context-menu', borderRadius: '20px', padding: '5px 10px' }}> */}
      {/* <div className='box p-2' style={{ cursor: 'context-menu', borderRadius: '20px'}}> */}
                

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div className='mt-3 mb-2 skeleton_shadow' style={{ padding: '0px 0px' }}  hidden={ !loading  }>
        <SkeletonTheme height={65}> <Skeleton count={1} baseColor={skeletonBaseColor} /> </SkeletonTheme>
      </div>

      <div hidden={ !loading } className='text-center'><PulseLoader color={"#2c3e50"} loading={true} size={8}/></div>

                {accounts.map((e, index) => <AccountCard 
                      key={index}
                      bankName={e.bank_name} 
                      accountNumber={e.account_number} 
                      accountName={e.account_name} 
                      charge={charge} 
                      chargeType={chargeType} 
              />)}

                  
                  
              {/* </div> */}
                
        </div>
       
          </div>
                        
        </div>						
    </section>
{/* <!-- /.content --> */}
  </div>
</div>


        </div>

    </div>
  )
}

export default VirtualAccount