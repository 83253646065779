import React, { useState } from 'react'

function TV (props) {   
    const [selectedTV, setSelectedTV] = useState('');

  
    function isSelected(provider) {
      return selectedTV == provider && selectedTV !== '';
    }

    function cable(provider) {
      if (provider !== '') {
        props.provider(provider);
        setSelectedTV(provider);
      }
    }
    

  return (

    <div className='box custom_card_shadow' style={{ backgroundColor: '#eef2f480', boxShadow: 'box-shadow: 1.171px 9.607px 15px 0px rgb(71 95 123 / 20%)', margin: '0px 20px 0px 20px' }}>
      <div className='row'>
        <div className='col-4 col_padding'>
          <div className={'network-card ' + (isSelected('DSTV') ? 'selected-blue-border' : '')} 
          onClick={() => cable('DSTV')} style={{ borderBottomLeftRadius: '10px', borderTopLeftRadius: '10px' 
          }}>
            {isSelected('DSTV') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/dstv.png' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">MTN</a></h5>  */}
            </div>
          </div>
        </div>


        <div className='col-4 col_padding'>
          <div className={'network-card ' + (isSelected('GOTV') ? 'selected-gotv' : '')}
           onClick={() => cable('GOTV')}>
            {isSelected('GOTV') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/gotv.png' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">GLO</a></h5> */}
            </div>
          </div>
        </div>


        <div className='col-4 col_padding'>
          <div className={'network-card ' + (isSelected('STARTIMES') ? 'selected-blue-border' : '')} 
          onClick={() => cable('STARTIMES')} style={{ borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }}>
            {isSelected('STARTIMES') ? <i className="mdi mdi-check-circle icon text-white overlay_icon"></i> : ''}
            <div className='text-center pt-3'>
              <a href='#'><img className='network-img' src='/images/startimes.png' alt='' /></a>
              {/* <h5 className="mt-2 mb-0 pb-1 network-font"><a href="#">9MOBILE</a></h5>                   */}
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default TV
