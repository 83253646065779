import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { numberWithCommas } from '../../functions/O3developer';



function WalletCard(props) {

  const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true": 
          return true;

        case "false": 
        case null: 
        case undefined:
          return false;

        default: 
          return JSON.parse(stringValue);
    }
  }
  
  const showBalance = localStorage.getItem("show_balance");
  const [visibility, setVisibility] = useState(showBalance == undefined ? false : stringToBoolean(showBalance));

  function balanceVisibility() {
    localStorage.setItem("show_balance", !visibility);
    setVisibility(!visibility);
    // console.log(visibility);
  }




  return (
    <div>
        
        <div data-aos="zoom-in" className="box box-body p-10 bg-white card_style custom_card_shadow wallet_card mt-2">
                <div className="media align-items-center p-0 mb-2" style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                  <div className="text-center" style={{ margin: "0 1px" }}>
              
                  <div style={{ border: "1px dashed #fff", height: '50px', width: '50px', borderRadius: "50%", padding: "10px", boxShadow: "rgb(17 12 46 / 15%) 0px 48px 100px 0px" }}>
                      <img src="../images/simple-wallet-with-credit-card-inside-free-vector.jpg" width="35px" />
                  </div>
                  
                  </div>
                  <div style={{ margin: "0 1px" }}>
                    <Link to={'/fund-wallet'}> <button className='btn btn-primary fund_wallet_btn' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}> Fund Wallet <i className='fa fa-plus'></i> </button> </Link>
                  </div>
                </div>


                <div className="flexbox align-items-center mt-15">

                <div className="mt-9 p-1"> 
                  <div className="text-right">
                    <p className="no-margin"><span className='text-dark wallet-card-text' style={{ textAlign: 'left'}}>Comission</span></p>
                    <p className="no-margin balance_text text-dark" style={{ textAlign: 'left' }}> 
                    <span hidden={ visibility == false ? true : false}>
                      { props.commission === "" ? <BeatLoader color='#2c3e50' size={8}></BeatLoader> : null }
                      { props.commission !== "" ? "₦" : null }{ props.commission === "" ? null : numberWithCommas(Math.round(props.commission * 100) / 100) }
                    </span>
                    <span hidden={ visibility == false ? false : true}> ***** </span>
                    </p>
                  </div>

                </div>


                <i onClick={balanceVisibility} className={ visibility == false ? 'fas fa-eye-slash' : 'fas fa-eye'}/>

                <div className="mt-10">
                  <div className="text-right">
                    <p className="no-margin"><span className="text-dark wallet-card-text">Wallet Balance</span></p>
                    <p className="no-margin balance_text text-dark">
                    <span hidden={ visibility == false ? true : false}>
                      { props.wallet === "" ? <BeatLoader color='#2c3e50' size={8}></BeatLoader> : null }
                      { props.wallet !== "" ? "₦" : null }{ props.wallet === "" ? null : numberWithCommas(Math.round(props.wallet * 100) / 100) }
                    </span>
                    <span hidden={ visibility == false ? false : true}> ***** </span>
                    </p>
                  </div>
                </div>
              </div>			
            </div>

    </div>
  )
}

export default WalletCard